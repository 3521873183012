.pd-8-input {
    padding: 8px !important;
}

.pd-4-input {
    padding: 4px !important;
}

.hei-input {
    height: 28px !important;
}

.color-red {
    color: red;
}

.change-avt {
    cursor: pointer;
    width: 36px;
    display: inline-block;
    vertical-align: bottom;
    height: 36px;
    position: absolute;
    border-radius: 50%;
    background: #fafafa;
    top: 134px;
    line-height: 36px;
    text-align: center;
    /*right: 170px;*/
    padding-left: 1px;
}

.change-avt:hover {
    background: #1b90bd;
    color: #fff;
}
.img-supervisor {
    width: 150px;
    height: 150px;
}
.size-text-header{
    font-size: 28px;
    font-weight: bold;
}
.border-color{
    border-color: #cccccc;
}
.change-password{
    cursor: pointer;
    color: #1d5d90;
}
.change-password:hover{
     cursor: pointer;
     color: #2DA5FF;
 }
.color-blue{
    color: blue;
}
.text-header-profile{
    font-size: 20px;
}
.w-auto{
    width: 100%!important;
    border: 1px solid !important ;
    border-radius: 4px!important;
    border-color: #ced4da!important;
}
.w-autoerr{
    width: 100%!important;
    border: 1px solid !important ;
    border-radius: 4px!important;
    border-color: red!important;
}
.react-datepicker-wrapper{
    width: 100%!important;
    padding-top: 4px!important;
    padding-bottom: 4px!important;
}
.border-input-error{
    border-color: red!important;
}
.react-datepicker-wrapper2{
    width: 100%!important;
    padding-top: 3px!important;
    padding-bottom: 4px!important;
}
.w-auto2{
    /*height: 150%!important;*/
    border: 1px solid !important ;
    border-top-left-radius: 4px!important;
    border-bottom-left-radius: 4px!important;
    border-color: #ced4da!important;
}
.w-auto3{
    /*height: 150%!important;*/
    border: 1px solid !important ;
    border-top-right-radius: 4px!important;
    border-bottom-right-radius: 4px!important;
    border-color: #ced4da!important;
}
/*.react-datepicker__input-container{*/
/*    height:110% !important*/
/*}*/
