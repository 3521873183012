
.root {
  float: right;
  margin: 6px 0 0;
}

.link {
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
  font-size: 1.125em; /* ~18px */
}

.link,
.link:active,
.link:visited {
  color: rgba(255, 255, 255, 0.6);
}

.link:hover {
  color: rgba(255, 255, 255, 1);
}

.highlight {
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.highlight:hover {
  background: rgba(0, 0, 0, 0.3);
}

.spacer {
  color: rgba(255, 255, 255, 0.3);
}

.inputPassword {
  /*height: 35px;*/
  /*!*background-color: blue;*!*/
  border-radius: 5px;
  border-width: 1px ;
  border-style: solid;
}

.inputSuggest{
  /*height: 35px;*/
  /*!*background-color: blue;*!*/
  border-radius: 5px !important;
  border-width: 1px !important;
  border-style: solid !important;
}
.form-control .inputSuggest2{
  border-radius: 0px !important;
  border-width: 0px !important;
}

.background-btn{
  background-color: #2DA5FF;
}
.border-input-error{
  border-color: red!important;
  border-radius: 5px;
  border-width: 1px ;
  border-style: solid;
}
.font-err{
  color: red;
  font-size: xx-small;
  width: auto !important;
}
.width-btn{
  width: 100px!important;
}

/*.modal-assign-order .modal-content .modal-body{*/
/*  overflow: visible !important;*/
/*}*/

/*.modal-content .modal-body{*/
/*  overflow: visible !important;*/
/*}*/
.modal-add-new-Sup{
  max-width:700px!important;
}


.root {
  float: right;
  margin: 6px 0 0;
}

.link {
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
  font-size: 1.125em; /* ~18px */
}

.link,
.link:active,
.link:visited {
  color: rgba(255, 255, 255, 0.6);
}

.link:hover {
  color: rgba(255, 255, 255, 1);
}

.highlight {
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.highlight:hover {
  background: rgba(0, 0, 0, 0.3);
}

.spacer {
  color: rgba(255, 255, 255, 0.3);
}

.inputPassword {
  /*height: 35px;*/
  /*!*background-color: blue;*!*/
  border-radius: 5px;
  border-width: 1px ;
  border-style: solid;
}
.background-btn{
  background-color: #2DA5FF;
}
.border-input-error{
  border-color: red!important;
  border-radius: 5px;
  border-width: 1px ;
  border-style: solid;
}
.font-err{
  color: red;
  font-size: xx-small;
  width: auto !important;
}
.width-btn{
  width: 100px!important;
}

/*.modal-assign-order .modal-content .modal-body{*/
/*  overflow: visible !important;*/
/*}*/

/*.modal-content .modal-body{*/
/*  overflow: visible !important;*/
/*}*/
.modal-add-new-Sup{
  max-width:700px!important;
}

.modal .modal-content{
  border: 5px solid #c4c4c4;
  border-radius: 35px !important;
}

.modal .btn-close {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #dedcdc;
  box-shadow: -2px 3px 2px rgba(0, 0, 0, .2);
}

.modal .btn-close .close {
  float: none; /* color: #FFFFFF; */
  text-shadow: none;
  font-size: 26px;
  opacity: 1;
  width: 40px;
  height: 40px;
  line-height: unset;
}

.modal .btn-close:hover{
  background-color: #57afce;
}
.modal .btn-close:hover .close{
  color: #fff;
}

.btn-primary{
  background-color: #57afce !important;
  border-color: #57afce !important;
}
.btn-secondary{
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
}

.font-size-content{
  font-size: 16px !important;
  font-weight: normal !important;
}
