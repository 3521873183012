.app-footer {
  background: url('../../assets/images/footer-banner.png') center center
    no-repeat;
  background-size: cover;
  padding: 60px;
}

.footer-col-1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer-col-1 img {
  max-width: 200px;
  margin-bottom: 30px;
}

.footer-col-1 ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-col-1 ul li {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.footer-col-1 ul li.contact-item img {
  max-width: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}

.footer-col-1 ul li.contact-item div.contact-infor {
  text-align: left;
  word-break: break-word;
  flex-grow: 1;
}

.footer-col-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.basic-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.basic-ul li {
    margin-bottom: 10px;
}

.basic-ul li a {
  color: #0f244e;
}

.footer-title {
    margin-bottom: 40px;
    color: #0f244e;
    font-size: 18px;
    font-weight: bold;
}

.second-title {
    margin-top: 40px;
}

.social img {
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
}

.social{
    vertical-align: middle !important;
    line-height: 24px;
}
