.banner img.ab-logo {
  width: 480px;
  margin-top: 20px;
  margin-bottom: 0px;
  max-width: 100%;
}

.about-us-wrapper .banner {
  padding: 28px;
}

.about-us-title {
  color: #55a9f6;
  text-transform: capitalize;
  font-weight: bold;
  font-style: italic;
  font-size: 24px;
}

.banner img.q1 {
  transform: translateY(-20px);
  max-width: 20px;
  margin-right: 8px;
}
.banner img.q2 {
  transform: translateY(-20px);
  max-width: 20px;
  margin-left: 8px;
}

.about-us-des p {
  margin-bottom: 0px !important;
  word-break: break-word;
  font-size: 20px;
}

.ab-block {
  padding: 40px 15px;
  align-items: center;
}

.ab-block .img-part img {
  max-width: 100%;
}

.ab-block .ab-content img.content-logo {
  width: 90px;
  vertical-align: baseline;
}
.content-logo-1 {
  width: 120px;
  vertical-align: baseline;
}

.ab-block .ab-content-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #133476;
  margin-top: 20px;
  word-break: break-word;
}
.ab-block .number-count {
  color: #55a9f6;
  font-size: 100px;
  font-style: italic;
  font-weight: 900;
  line-height: 1;
}
img.content-oke {
  width: 20px;
  margin-top: -2px;
}
@media screen and (min-width: 600px) {
  .about-us-title {
    font-size: 26px;
  }
  .about-us-wrapper .banner img.q1 {
    transform: translateY(-20px);
    max-width: 22px;
    margin-right: 10px;
  }
  .about-us-wrapper .banner img.q2 {
    transform: translateY(-20px);
    max-width: 22px;
    margin-left: 10px;
  }
  .about-us-wrapper .banner {
    padding: 30px;
  }
}

@media screen and (min-width: 960px) {
  .about-us-title {
    font-size: 34px;
  }
  .about-us-wrapper .banner img.q1 {
    transform: translateY(-20px);
    max-width: 30px;
    margin-right: 12px;
  }
  .about-us-wrapper .banner img.q2 {
    transform: translateY(20px);
    max-width: 30px;
    margin-left: 12px;
  }
  .about-us-wrapper .banner {
    padding: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .about-us-title {
    font-size: 42px;
  }
  .about-us-wrapper .banner img.q1 {
    transform: translateY(-20px);
    max-width: 38px;
    margin-right: 14px;
  }
  .about-us-wrapper .banner img.q2 {
    transform: translateY(20px);
    max-width: 38px;
    margin-left: 14px;
  }
  .about-us-wrapper .banner {
    padding: 34px;
  }
}

@media screen and (min-width: 1280px) {
  .about-us-title {
    font-size: 50px;
  }
  .about-us-wrapper .banner img.q1 {
    transform: translateY(-20px);
    max-width: 46px;
    margin-right: 16px;
  }
  .about-us-wrapper .banner img.q2 {
    transform: translateY(20px);
    max-width: 46px;
    margin-left: 16px;
  }
  .about-us-wrapper .banner {
    padding: 36px;
  }
}

@media screen and (min-width: 1600px) {
  .about-us-title {
    font-size: 58px;
  }
  .about-us-wrapper .banner img.q1 {
    transform: translateY(-20px);
    max-width: 52px;
    margin-right: 18px;
  }
  .about-us-wrapper .banner img.q2 {
    transform: translateY(20px);
    max-width: 52px;
    margin-left: 18px;
  }
  .about-us-wrapper .banner {
    padding: 38px;
  }
}

@media screen and (min-width: 1920px) {
  .about-us-title {
    font-size: 64px;
  }
  .about-us-wrapper .banner img.q1 {
    transform: translateY(-20px);
    max-width: 60px;
    margin-right: 20px;
  }
  .about-us-wrapper .banner img.q2 {
    transform: translateY(20px);
    max-width: 60px;
    margin-left: 20px;
  }
  .about-us-wrapper .banner {
    padding: 40px;
  }
}
