@charset "utf-8";
/* List category

* - Common

1 - Header

2 - Banner

3 -

4 -

5 -

6 -

7 -

8 -


*/
/*---------------------------------------------------------------------------------*/
@import url(../css/animate.css);
@import url(../css/fontawesome/css/all.min.css);
@import url(../css/fontawesome/css/fontawesome.min.css);

/*@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i&subset=vietnamese');*/

/*******************
Preloader
********************/

.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 999999999;
  background: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

.loader,
.loader__figure {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  overflow: visible;
  padding-top: 2em;
  height: 0;
  width: 2em;
}

.loader__figure {
  height: 0;
  width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid #1bbda5;
  border-radius: 50%;
  -webkit-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.loader__label {
  float: left;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0.5em 0 0 50%;
  font-size: 0.875em;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  color: #1a2533;
  white-space: nowrap;
  -webkit-animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #1976d2;
  }
  29% {
    background-color: #1976d2;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #1976d2;
  }
  29% {
    background-color: #1976d2;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #1976d2;
  }
  29% {
    background-color: #1976d2;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@-moz-keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

/*------------------- 1.Common --------------------*/

body {
  background: #fff;
  font-size: 14px !important;
  /*background: #f9f9fc;*/
  /*font-family: 'Open Sans', 'Helvetica', 'Arial';*/
  /*font-family: 'Quicksand', sans-serif !important*/;
  font-family: 'Montserrat';
  color: #313131;
}

.bg-dark {
  background: #242221 !important
}

ul, ol {
  margin-bottom: 0;
  padding: 0
}

ol li {
  list-style: decimal inside
}

ul li {
  list-style: none
}

button:hover {
  cursor: pointer
}

h1, h2, h3, h4, h5 {
  font-weight: 600;
  color: #1a2533;
  /*font-family: 'Open Sans', 'Helvetica', 'Arial';*/
  font-family: 'Montserrat', 'Segoe UI', 'Helvetica Neue';
}

.table td, .table th {
  padding: .5rem;
  vertical-align: middle !important;
  border-top: 1px solid #eaeaea;
}

a {
  color: #6cb5f9;
}

a:hover, a:focus {
  text-decoration: none;
  color: #0c7fe2;
  outline: none !important;
  box-shadow: none !important
}

.navbar-brand {
  font-size: 24px;
  color: #ffc108 !important;
  margin: 0 !important;
}

.font-weight-semibold {
  font-weight: 600
}

.form-group {
  margin-bottom: .7rem;
}

.dropdown-toggle::after {
  display: none
}

.page-link {
  color: #777777; /* background: none !important; *//* border-color: #3f454c !important; */
}

.page-item.active .page-link {
  background-color: #eaeaea;
  border-color: #dee2e6;
  color: #bfbfbf;
}

.alert {
  padding: 8px 16px;
}

.alert-warning {
  background-color: #fbf0e3;
  font-size: .8rem;
  border: none;
  text-align: center
}

.highlight-link {
  color: #0c7fe2;
  cursor: pointer;
}

.highlight-link:hover, .highlight-link:focus {
  color: #ff7c2a;
}

.text-info {
  color: #19b39c !important;
}

.btn-danger {
  color: #fff6d5;
  background-color: #c93c3c !important;
  border-color: transparent !important;
  box-shadow: 0 0 0.2rem rgba(255, 193, 7, .5);
}

.btn-original {
  min-width: 200px;
  border-radius: 30px; /* text-transform: uppercase; */
  font-weight: 600; /* letter-spacing: .5px; */
  color: #1a2533;
  cursor: pointer;
  padding: 8px 24px;
  margin-bottom: 2px;
  font-size: 18px;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  position: relative;
}

.btn-1st, .btn-1st:focus {
  background: #ea7b22;
  background: -moz-linear-gradient(left, #ff9920 0%, #ff7b2c 50%) !important;
  /* background: -webkit-linear-gradient(left, #ff9920 0%,#ff7b2c 100%) !important; */
  /* background: linear-gradient(to right, #ff9920 0%,#ff7b2c 100%) !important; */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9920', endColorstr='#ff7b2c', GradientType=1);
  color: #fff;
}

.btn-1st:hover, .btn-1st:focus {
  /*background: #ea7b22;*/
  background: #85c525;
  /*background: -moz-linear-gradient(left, #ff7b2c 0%, #ff9920 100%) !important;*/
  /*background: -webkit-linear-gradient(left, #ff7b2c 0%, #ff9920 100%) !important;*/
  /*background: linear-gradient(to right, #ff7b2c 0%, #ff9920 100%) !important;*/
  background: -moz-linear-gradient(left, #85c525 0%, #55a9f6 100%) !important;
  background: -webkit-linear-gradient(left, #85c525 0%, #55a9f6 100%) !important;
  background: linear-gradient(to right, #85c525 0%, #55a9f6 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7b2c', endColorstr='#ff9920', GradientType=1);
  color: #fff
}

.btn-2nd, .btn-2nd:focus {
  background: #1bbda5;
  background: -moz-linear-gradient(left, #1bbda5 0%, #1bbda5 100%) !important;
  /* background: -webkit-linear-gradient(left, #1bbda5 0%,#1bbda5 100%) !important; */
  /* background: linear-gradient(to right, #1bbda5 0%,#1bbd67 100%) !important; */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1bbda5', endColorstr='#1bbda5', GradientType=1);
  position: relative;
  color: #1bbda5;
}

.btn-2nd span {
  position: relative
}

.btn-2nd:before {
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  color: #1bbda5;
  content: "";
  border-radius: 24px;
  background: #fff;
}

.btn-original span {
  display: inline-block;
  position: relative;
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  will-change: transform;
}

.btn-original:hover span, .btn-original:focus span {
  -webkit-transform: translate3d(-0.8rem, 0, 0);
  transform: translate3d(-0.4rem, 0, 0);
}

.btn-original i {
  position: absolute;
  width: .8em;
  right: 0px;
  right: 0rem;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  will-change: right, opacity;
  font-size: .8rem;
}

.btn-original:hover i, .btn-original:focus i {
  opacity: 1;
  right: -1.4rem;
}

.btn-warning {
  background: #ea7b22 !important;
  color: #fff !important;
}

.btn-info {
  background: #1bbda5 !important;
}

.btn-secondary, .btn-warning, .btn-info {
  border-radius: 8px;
  padding: 8px 24px;
  border: none !important;
  min-width: 120px
}

.title-1st {
  font-weight: 400;
  margin-bottom: 16px;
  color: #4a4c4e;
}

.table-active, .table-active > td {
  background: #1bbda5;
  color: #fff;
}

.table-active, .table-active > th {
  background: #1bbda5;
  color: #fff;
}

td > a.text-dark {
  color: #c5c5c5 !important
}

td > a.text-dark:hover, td > a.text-dark:focus {
  color: #a6a4a4 !important
}

td.text-danger {
  color: #ffc108 !important
}

.table-bordered td {
  border: 1px solid #ced4da;
}

.breadcrumb {
  background: #00000026;
}

.services, .wcu, .partner {
  margin-top: 48px;
  padding-top: 40px;
}

.wcu, .partner {
  border-top: 1px solid #efefef
}

.partner {
  padding-bottom: 48px
}

/*------------------ 2.HEADER --------------------*/

header {
  background: #fff;
  border-bottom: 1px solid #efefef;
}

.affix {
  /*position: sticky;*/
  top: -1px;
  z-index: 100;
}

/*.top-header {*/
/*  background: #1bbda5;*/
/*  background: -moz-linear-gradient(left, #1bbda5 0%, #1bbda5 100%);*/
/*  background: -webkit-linear-gradient(left, #1bbda5 0%, #1bbda5 100%);*/
/*  background: linear-gradient(to right, #1bbda5 0%, #1bbda5 100%);*/
/*  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1bbda5', endColorstr='#1bbda5', GradientType=1);*/
/*}*/
.top-header {
  /*background: #C6E6F1;*/
  background: linear-gradient(to right, #3ba5f7, #0075dd);
  background: -moz-linear-gradient(to right, #3ba5f7, #0075dd);
  background: -webkit-linear-gradient(to right, #3ba5f7, #0075dd);
  /*background: #C6E6F1;*/
  /*background: -moz-linear-gradient(left, #C6E6F1 0%, #C6E6F1 100%);*/
  /*background: -webkit-linear-gradient(left, #C6E6F1 0%, #C6E6F1 100%);*/
  /*background: linear-gradient(to right, #C6E6F1 0%, #C6E6F1 100%);*/
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#C6E6F1', endColorstr='#C6E6F1', GradientType=1);
}

.top-header li, .top-header li a {
  color: #fff
}

.top-header li {
  padding: 6px 0;
}

.top-header li a, .top-header li span {
  padding: 0 8px 0 18px;
  border-left: 1px solid rgba(0, 0, 0, .1)
}

.top-header li:first-child a {
  border-left: none;
}

.top-header li:last-child a {
  padding-right: 0
}

.top-header li .hotline {
  font-size: 18px
}

.top-header li .help {
  font-size: 14px
}

.navbar .nav-item .nav-link { /* text-transform: uppercase; *//* font-weight: 600; */
  font-size: 15px; /* letter-spacing: .25px; */
  color: #171717;
  font-family: 'Montserrat', 'Open Sans', 'Helvetica', 'Arial';
}

.navbar .nav-item:hover .nav-link, .navbar .nav-item:focus .nav-link {
  color: #C6E6F1;
  /*color: #1bbda5;*/
}

.navbar .navbar-user .btn-signup {
  border: 2px solid #ffffff63;
  color: #fff;
  font-size: 16px;
  padding: 7px 24px;
}

.navbar .navbar-user .btn-signup.btn-signin {
  border-color: transparent !important;
  color: #fff !important;
  font-weight: 600;
  padding: 1px 20px 0;
  background: #ea7b22;
  background: -moz-linear-gradient(left, #ff9920 0%, #ff7b2c 100%);
  background: -webkit-linear-gradient(left, #ff9920 0%, #ff7b2c 100%);
  background: linear-gradient(to right, #ff9920 0%, #ff7b2c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9920', endColorstr='#ff7b2c', GradientType=1);
}

.main-mn .nav-item.active .nav-link {
  color: #C6E6F1;
}

.navbar .navbar-user .btn-signup.btn-signin i {
  font-size: 21px;
  vertical-align: inherit;
  margin-right: 6px;
}

.thumb {
  width: 40px;
  height: 40px;
  /*background: #ea7b22;*/
  line-height: 40px;
  color: #fff;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  font-size: 18px;
}

.caret {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

/*------------------ 3.BANNER --------------------*/
.main-banner { /*background: #edf7f3*/
}

.main-banner .opt-1 {
  padding: 32px 0;
  background: #eafcff
}

.main-banner h1 {
  font-size: 2.4rem;
  font-weight: 300;
}

.main-banner h1 span {
  display: block;
  font-weight: 300
}

.main-banner .opt-2 .ct-opt2 {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, .25);
  left: 0;
  right: 0;
  margin: auto;
  padding: 4px 0 2px;
}

.main-banner .opt-2 .ct-opt2 h1 {
  color: #fff;
  font-size: 24px
}

.main-banner .carousel-indicators {
  justify-content: start;
  margin: auto;
  padding-left: 15px;
  bottom: 72px;
}

.main-banner .carousel-indicators .active {
  background-color: #C6E6F1
}
input::placeholder {
  color: #cccccc;
  font-style: italic;
}
textarea::placeholder {
  color: #cccccc;
  font-style: italic;
}
.main-banner .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.14);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}

.main-banner .bg-bn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.m-ct { /* margin-top: -80px; */
}

.m-ct .col-md-4 {
  margin-top: 16px;
}

.m-ct .call-to-action .btn-original {
  font-size: 24px;
  text-transform: capitalize;
}

.m-ct .call-to-action a {
  color: #C6E6F1;
  text-decoration: underline
}

.btn-search {
  width: 36px;
  height: 36px;
  padding: 0;
  color: #8a8a8a !important;
}

.btn-search:before {
  font-size: 14px
}

.search form {
  padding: 8px 0;
}

.search input.form-control {
  font-size: 1.2em;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  margin-right: 10px;
  background: none;
}

.search input.form-control:focus {
  box-shadow: none
}

.search .btn-search-submit {
  width: 40px;
  height: 40px
}

.nav-link.collapsed .flaticon-search:before {
  content: "\f111";
  font-size: 16px
}

.nav-link .flaticon-search:before {
  content: "\f112";
  font-size: .75em;
}

.container nav {
}

.wrap {
  max-width: 1100px;
  margin: 0 auto
}

.main-ct .wrap { /* border:1px solid #ddd; */
  padding: 20px 20px 30px;
  border-top: 0;
  background: #2e3338;
  margin-top: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.38);
  border-radius: 3px;
}

.number-table .col-md-3, .number-table .col-md-9 {
  background: #2e3338;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.38);
}

.main-ct .col-md-8 {
  border-right: 1px solid #ddd;
}

.main-ct .col-md-4 {
  padding-left: 30px
}

.main-ct .form-group.col-md-4 {
  padding-left: 5px
}

.main-ct .col-md-8 h5 {
  margin-bottom: 40px
}

.main-mn .dropdown-menu {
}

.main-mn .dropdown-menu h5 {
  padding-left: 25px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase
}

.main-mn .dropdown-menu h3, .main-mn .dropdown-menu h4 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 16px;
}

.main-mn .dropdown-menu h4 {
  font-size: 14px;
  padding-left: 0;
  margin-bottom: 2px;
  color: #000000;
  font-weight: 500;
}

.main-mn .dropdown-menu a, .navbar-user .dropdown-menu a {
  color: rgba(0, 0, 0, 0.75);
  font-size: 13px; /* border-bottom: 1px solid rgba(0,0,0,.1) */
  font-weight: 300;
  margin-bottom: 8px;
  padding-left: 0;
  padding-right: 0;
}

.main-mn .dropdown-menu li:last-child a, .navbar-user .dropdown-menu li:last-child a {
  border-bottom: none
}

.main-mn .dropdown-menu a:hover, .main-mn .dropdown-menu a:focus, .main-mn .dropdown-menu a:hover h4, .main-mn .dropdown-menu a:focus h4, .navbar-user .dropdown-menu a:hover, .navbar-user .dropdown-menu a:focus {
  background: none;
  color: #C6E6F1;
}

.navbar .navbar-user .cart {
  position: relative
}

.cart-notify {
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #ffc106;
  text-align: center;
  line-height: 22px;
  font-weight: 500;
  right: -5px;
  top: 0px;
}

.news .card-deck {
  margin-top: 50px;
  font-size: 14px;
  border: none !important;
}

.news .card-deck h5 {
  padding: 12px 20px;
  margin-bottom: 0; /* background: #f4f4f4; */
  font-size: 20px;
}

.news .card-deck ul {
}

.news .card-deck ul li {
  padding: 10px 0 10px;
  border-bottom: 1px dashed #eee
}

.news .card-deck ul li:last-child {
  border-bottom: none
}

.news .card-deck .card {
  border: none
}

.news .card-body {
  padding-top: 5px;
  padding-bottom: 5px
}

.buying-code-mobile {
  margin-top: 30px
}

.buying-code-mobile .input-group-text {
  min-width: 70px;
  justify-content: center;
  background: #4e565f;
  color: #eee;
  border: none;
}

.btn{
  /*height: 100%;*/
  /*border-color: #57afce;*/
}

.btn-primary-collapse:hover .tootip-checkbox {
  display: block;
}
.btn-primary{
  background-color: #57afce;
  border-color: #57afce;
}
.btn-primary:hover{
  background-color: #57afce;
  border-color: #57afce;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #57afce;
  border-color: #57afce;
}

.table.percent {
  font-size: 13px
}

select.form-control, input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
  font-size: 14px;
  padding-left: 8px;
}

.price {
  text-decoration: underline
}

.avatar {
  background-position: center center;
  background-repeat: no-repeat;
  width: 128px;
  height: 128px;
  display: inline-block;
  position: relative; /* border: 4px solid #fff; *//* border-radius: 50%; */
}

.change-avatar {
  position: absolute;
  bottom: -24px;
  right: 8px;
  width: 36px;
  height: 36px;
  display: inline-block;
  background: #fff;
  color: #000;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
}

.change-avatar:hover, .change-avatar:focus {
  background: #ea7b22;
  color: #fff
}

.number-table .col-md-3 .wrap-col, .number-table .col-md-9 .wrap-col {
  padding: 20px 5px;
}

.list-sim .wrap {
  background: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
}

.viewest-box h6 {
  font-size: .9rem;
  line-height: 1.5;
  margin-top: -3px !important;
}

/*-------------------3 - SERVICES-------------------*/
.services {
  border-top: 1px solid #efefef
}

.services .item p {
  color: #898989
}

.services h4, .subtitle1 {
  font-size: 1.4rem;
  font-weight: 600;
}

.services .item .figure-ico {
  font-size: 72px;
  color: #000
}

.services .owl-dots {
  margin-top: 16px
}

.wcu {
  padding-bottom: 48px
}

.wcu .owl-prev, .wcu .owl-next {
  text-indent: -9999px;
  display: inline-block
}

.wcu .owl-nav, .services .owl-nav {
  display: none;
  position: absolute;
  top: 21%;
  left: 0;
  right: 0
}

.wcu:hover .owl-nav {
  display: block
}

.wcu .owl-prev:before, .wcu .owl-next:before {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 21%;
  text-indent: initial;
  font-family: "Flaticon";
  font-size: 60px;
  opacity: .3;
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  color: #C6E6F1
}

.wcu .owl-prev:before {
  content: "\f109";
  left: -56px;
}

.wcu .owl-next:before {
  content: "\f108";
  right: -56px;
}

.wcu .owl-prev:hover:before, .wcu .owl-next:hover:before {
  opacity: 1;
}

/*3 - PAYMENT FORM*/

.user-mn {
  color: rgb(255, 255, 255);
}

.user-mn > li {
  margin-right: -5px !important
}

.user-mn > li > a {
  color: #fff;
  padding: 20px
}

.user-mn > li > a:hover, .user-mn > li > a:focus, .user-mn > li.name > a {
  color: #f9ffaf !important
}

.user-mn > li.active > a {
  background: rgba(0, 0, 0, .10);
  color: #f9ffaf !important
}

.user-mn > li:last-child {
  border-left: 1px solid rgb(242, 172, 182);
  padding-left: 20px;
}

.user-mn > li:last-child > a {
  padding-right: 0
}

.user-mn > li.name .dropdown-menu {
  left: inherit;
  right: 0;
  top: 30px;
  background: #f9ffaf;
  border: none;
  text-align: center;
}

.dropdown-toggle::after {
  vertical-align: unset;
  margin-right: 5px;
  margin-left: 8px
}

.user-mn > li.name .dropdown-menu a:hover, .user-mn > li.name .dropdown-menu a:focus {
  background: none;
}

.log-box {
  margin-top: 30px
}

.help .card-header {
  padding-left: 0;
  background: #fff
}

.help .card-body {
  padding: 10px 20px
}

.help .card-header a {
  font-weight: 500
}

.login-p {
  min-height: 500px;
  margin-top: 30px
}

.hide-help {
  display: none;
  background: rgba(0, 0, 0, .8);
}

.input-numberphone {
  position: relative
}

.input-numberphone input[type="text"]:focus + .hide-help {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
}

.search-number {
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3f3cc9;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ea7b22;
  border: 3px solid #f4f8fa;
}

.filter-opt {
  padding-bottom: 20px;
  margin-bottom: 0;
}

.filter-opt .txt label {
  padding-top: 3px;
  padding-left: 15px;
}

.telephone .custom-control-label::before, .telephone .custom-control-label::after {
  top: .8rem
}

.telephone .custom-control {
  border-bottom: 1px dotted #5f676f;
  padding-bottom: 2px;
  margin-bottom: 6px;
  height: 50px;
}

.telephone.txt .custom-control {
  height: 36px
}

.telephone.txt .custom-control-label::before, .telephone.txt .custom-control-label::after {
  top: .4rem
}

.telephone .custom-control-label img {
  padding-left: 15px
}

.number {
  font-style: normal;
  font-weight: 400;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  width: 22px;
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  margin-right: .5rem !important;
  align-self: center;
  padding-left: 5px;
}

.breadcrumb-box .breadcrumb {
  background: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px
}

.other-news {
}

.other-news ul li { /* list-style: disc inside; */
}

.other-news ul li a {
}

.ads {
  display: none
}

.ad-fixed-left, .ad-fixed-right {
  position: fixed;
  bottom: 0
}

.ad-fixed-left .close, .ad-fixed-right .close {
  background: #000000bf;
  position: absolute;
  padding: 3px 8px;
  z-index: 10;
  top: 0;
  right: 0;
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

.ad-fixed-right {
  right: 0
}

.ad-fixed-right .close {
  right: inherit;
  left: 0
}

.top {
  top: 80px;
  bottom: inherit
}

.note li {
  list-style: disc inside !important
}

.help-buying {
  border-radius: 0px;
  background: #2e3338;
  color: #c5c5c5;
  border-color: #6f6f6f;
}

/****** 4 - LOGIN MODAL ******/
.modal-title {
  text-transform: uppercase
}

.modal-body {
  padding: 1rem 2rem
}

.modal .nav-tabs {
  border: none;
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.modal .nav-tabs > li {
  margin-bottom: -2px;
  width: 50%;
}

.modal .nav-tabs > li > a {
  color: rgba(29, 29, 29, 0.37);
  border: none;
  padding: 0 0 8px;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  text-transform: uppercase;
}

.modal .nav-tabs > li > a.active, .modal .nav-tabs > li > a.active:focus, .modal .nav-tabs > li > a.active:hover {
  color: #000000 !important;
  border-bottom: 2px solid #797979;
  border-radius: 0;
}

.modal .nav-tabs > li > a.active {
}

.modal .nav-tabs > li > a:hover {
  background: none;
  border-radius: 0;
  color: #ff9621;
}

.modal-dialog.modal-dialog-centered.modal-normal {
  max-width: 60% !important;
}

.modal .modal-content {
  border: 10px solid rgb(226, 221, 221);
  border-radius: 36px; /* background: #C6E6F1; */
}

.modal-content, .modal-card{
  overflow: visible !important;
}
.modal .btn-close {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 40px;
  height: 40px;
  z-index: 2000;
  background: #ffffff;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #dedcdc;
  box-shadow: -2px 3px 2px rgba(0, 0, 0, .2);
}

.modal .btn-close .close {
  position: absolute;
  float: none; /* color: #FFFFFF; */
  text-shadow: none;
  font-size: 26px;
  opacity: 1;
  right: -1px;
  width: 40px;
  height: 40px;
  line-height: unset;
}

.modal .btn-original {
  border-radius: 5px;
  text-transform: uppercase;
}

.modal .form-control {
  font-size: 13px;
  padding: 5px 8px;
  height: auto !important;
}

.modal .form-group {
  margin-bottom: .6rem
}

.modal{
  z-index: 1050 !important;
}

.modal-body{
  overflow-y: auto;
}

.bg-header {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #C6E6F1;
  height: 128px;
  border-radius: 30px 30px 0 0;
}

.loginmodal-container {
  /*max-width: 400px;*/
  margin: 0 auto;
}

.modal-header button.close {
  margin: 10px 20px;
  padding: 0;
}

.modal-header button.close, button.close:hover {
  box-shadow: none;
}

.modal {
  font-size: 14px;
}

.modal h1 {
  text-align: center;
  font-size: 1.8rem;
  color: #444444;
  margin-bottom: 24px;
  margin-top: 8px;
}

#request-customer h5 {
  font-size: 1rem;
  background: #C6E6F1;
  padding: 8px 16px;
  border-radius: 4px;
}

.loginmodal-container input[type=submit] {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  position: relative;
  padding: 12px 15px;
  font-weight: bold;
}

.login-help {
  font-size: 12px;
}

.verify-ct {
  background: #d7d7d7;
  width: 120px;
  height: 39px;
  display: inline-block;
}

.testimonial .testimonial-video {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* padding: 16px 0; */
}

.testimonial .wrap-testimonial-box {
  padding: 10% 0;
}

.testimonial .wrap-testimonial-box .display-4 {
  font-size: 80px;
  margin: 32px;
  display: block;
  color: #03b29a;
  position: relative
}

/*.testimonial .wrap-testimonial-box .display-4 span {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  display: inline-block;*/
/*  background: url(../images/line-video.png) no-repeat center*/
/*}*/

.testimonial h2 i {
  font-size: 16px;
  vertical-align: text-top;
}

.testimonial h2 i.flaticon-quote-left {
  margin-left: -16px;
}

.testimonial h2 i.flaticon-quote-right {
  margin-right: -16px;
}

.testimonial-list a {
  color: #1a2533
}

.testimonial-list .avatar, .testimonial-list .avatar img {
  width: 80px;
  height: 80px
}

.video-popup {
}

.video-popup .modal-content {
  background: none;
  border-top: 0;
  border: none
}

.video-popup .modal-body {
  background: #000
}

.video-popup .modal-header {
  border-bottom: 0;
}

.video-popup .modal-header button {
  top: -12px;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: none;
  right: -12px;
  font-size: 32px;
}

.partner .owl-nav {
  display: none
}

.owl-theme .owl-dot {
  display: inline-block
}

.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #C6E6F1;
}

/*******************
Pricing 1
*******************/
.pricing1, .faqs, .manage-account {
  padding-top: 48px;
  padding-bottom: 56px;
  border-bottom: 1px solid #efefef;
  background: #f4f8fa;
}

.pricing1 { /* background: #121821 */
}

.pricing1 .card {
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  border-radius: 6px;
  box-shadow: 0px 10px 56px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border: none;
  background: none;
}

.pricing1 .card:hover {
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -webkit-font-smoothing: antialiased;
}

.pricing1 .card .card-title {
  padding: 10px 16px 6px;
  color: #fff;
  border-radius: 6px 6px 0 0;
  margin-bottom: 0;
  position: relative; /* border: 4px solid #fff; */
}

.pricing1 .card .card-title-1 {
  background: #56aef9;
}

.pricing1 .card .card-title-2 {
  background: #C6E6F1;
}

.pricing1 .card .card-title-3 {
  background: #f58e32;
}

.pricing1 .card .card-title-4 {
  background: #862bff;
}

.pricing1 .card-body {
  padding-top: 8px;
  background: #fff;
}

.pricing1 .badge {
  top: -13px;
  position: absolute;
  left: 0;
  right: 0;
  width: 100px;
  margin: 0 auto;
}

.pricing1 .title {
  margin-bottom: 5px;
}

.pricing1 .subtitle1 {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.pricing1 .subtitle {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.74);
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
  min-height: 48px
}

.pricing1 .pricing-label {
  font-size: 27px
}

.pricing1 .pricing {
  min-height: 64px
}

.pricing1 .pricing sup {
  font-size: 18px;
  top: -20px;
}

.pricing1 .pricing .display-5 {
  color: #263238;
}

.pricing1 .pricing .yearly {
  display: none;
}

.pricing1 .list-inline {
  margin-top: 20px;
  min-height: 216px
}

.pricing1 .list-inline li {
  padding: 6px 0;
  display: block;
  font-size: 13px;
}

.pricing1 .bottom-btn {
  position: relative;
  bottom: 0px;
}

.tab-content .pricing1 {
  background: #fff;
  padding-top: 0
}

.tab-content .pricing1 .card {
  background: #f4f8fa;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.tab-content .pricing1 .card .card-title {
  border-radius: 6px 6px 0 0
}

.tab-content .pricing1 h5 {
  background: none !important;
  padding-bottom: 0 !important
}

.display-5 {
  font-size: 3rem;
}

.text-sm {
  font-size: 13px;
}

.faqs .subtitle1 {
  font-size: 1.1rem;
  margin-bottom: 16px;
}

.faqs .text-sm {
  padding-left: 20px;
  border-left: 4px solid #C6E6F1;
}

h6.subtitle {
  margin-top: 16px
}

.profile li {
  display: block
}

.menu-y {
  border-bottom: none
}

.menu-y li {
  display: block;
  width: 100%;
}

/*.menu-y li:hover {*/
/*  background-color: #e5f1f5;*/
/*}*/

.menu-y li a.active, .menu-y li a:hover, .menu-y li a:focus {
  /*color: rgba(0, 0, 0, 1)*/
  /*color: #C6E6F1*/
  background-color: #e5f1f5;
  color: #57afce
}

.menu-y li a.active {
  font-weight: 600
}

.menu-y li a.active i {
  font-weight: 400
}

.menu-y li a {
  color: rgba(0, 0, 0, .5);
  border-top: 1px solid #eee;
  padding: 0 .5rem;
}

.menu-y li:last-child a { /* border-bottom: 1px solid #eee; */
}

.menu-y li a i {
  font-size: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.menu-y li .submenu-y a {
  display: block;
  padding: 12px 24px
}

.menu-y li .submenu-y a i {
  font-size: 13px;
  border-left: .3em solid;
  border-bottom: .3em solid transparent;
  border-right: 0;
  border-top: .3em solid transparent;
}

.no-border-top{
  border-top: 0 !important;
}

.tab-content{
  padding-right: 0px !important;
  margin-bottom: 20px;
}
.tab-content.with-loading{
  position: relative;
}

.tab-content .tab-pane {
  padding: 12px 32px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
}

.tab-content .tab-pane#profile {
  padding: 0
}

.tab-content .tab-pane#profile > .row > .col-md-3 {
  background: #C6E6F1;
  text-align: center;
}

.tab-content .tab-pane#profile > .row > .col-md-3 .avatar {
  padding-top: 24px;

}

.tab-content .tab-pane#profile > .row > .col-md-9 {
  padding: 24px;
}

.media {
  margin-top: 20px;
}

.media h5 {
  font-size: 14px;
  line-height: 20px;
  margin-top: -2px !important;
}

.media h5 a {
  color: #666
}

.media img {
  background-position: center center;
  background-size: cover;
  display: inline-block;
  width: 96px;
  background-repeat: no-repeat;
  margin-right: 12px !important;
}

.category-list {
  padding-bottom: 48px
}

.category-list h4, .viewest-box h4 {
  color: #C6E6F1;
  font-weight: 600
}

.category-list .list-unstyled h4 {
  margin-top: -4px !important;
}

.category-list .list-unstyled h4 a {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.category-list .media {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px
}

.category-list .media img {
  width: 220px;
  margin-right: 16px !important;
}

.time-post {
  margin-bottom: 0;
  font-size: 13px;
  color: #C6E6F1;
  margin-top: 8px;
}

.sapo {
  font-weight: bold
}

.tags {
  margin-bottom: 16px
}

.tags li {
  display: inline-block
}

.tags li a {
  display: inline-block;
  background: #ff9621;
  color: #fff;
  padding: 2px 8px;
  font-size: 13px;
  border-radius: 4px;
}


.other-news {
  border-top: 1px solid rgba(0, 0, 0, .1);
  padding-top: 10px;
  margin-top: 16px;
  margin-bottom: 48px;
}

.short-link {
  margin-bottom: 32px
}

.short-link li {
  list-style: circle;
  list-style-position: outside;
  margin-left: 20px;
}

.short-link a {
  font-size: .9em;
  color: rgba(0, 0, 0, .80);
  padding: 4px 0;
  display: block;
}

.short-link a:hover, .short-link a:focus {
  color: #c90f0f
}

.avatar {
  width: 100%;
  height: auto;
  /*width: 160px;*/
  /*height: 160px*/
}

#m-contract h5 {
  background: #C6E6F1;
  padding: 8px 16px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
}

.list-type-table li {
  display: flex !important;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee
}

.list-type-table li span {
  display: block;
}

.list-type-table li span:first-child {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 16px;
  color: rgba(0, 0, 0, .7);
}

.list-type-table li span:last-child {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
  padding-left: 15px;
  font-weight: 600;
}

.filter select.form-control {
  height: auto !important
}

.filter .btn-warning, .filter .btn-info {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  border-radius: 4px;
}

.faq-box {
  border: 1px solid #C6E6F1
}

.faq-box .card-body {
  font-size: 13px;
  background: #eee
}

.faq-box .subfaqs {
  font-size: 13px
}

.payment .opt { /* border: 2px solid #1099dd; */
  text-align: center;
  font-size: 18px;
}

.payment .opt > .custom-control, .filter-box-more .custom-control {
  display: block;
  margin: 0;
  padding: 0
}

.payment .opt > .custom-control label:after, .filter-box-more .custom-control label:after, .filter-box-more .custom-control label:before {
  display: none
}

.payment .opt > .custom-control label:before, .filter-box-more .custom-control label:before {
  top: 12px;
  left: inherit;
  background: #f4f8fa;
  border: 1px solid rgb(185, 185, 185);
}

.payment .opt > .custom-control label, .filter-box-more .custom-control label {
  display: block;
  background: #f4f8fa;
  padding: 6px 24px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  color: #848282;
  margin-bottom: 12px;
}

.payment .opt > .custom-control input[type="radio"]:checked ~ label, .filter-box-more .custom-control input[type="radio"]:checked ~ label {
  background: #C6E6F1;
  color: #fff;
  border-color: #C6E6F1;
}

.payment .opt, .payment .opt input[type="radio"], .payment .opt label, .filter-box-more .custom-control label, .payment .custom-control-label {
  cursor: pointer;
}

footer {
  background: #121821;
  padding: 32px 20px 12px;
  /*position: fixed;*/
  width: 100%;
  bottom: 0;
}

footer h4 {
  color: rgba(255, 255, 255, .80);
  font-size: 16px;
  font-weight: 400;
}

footer li {
  padding: 2px 0 0px;
}

footer a {
  color: rgba(255, 255, 255, .30);
  font-size: 13px;
  display: inline-block;
}

footer .copyright {
  padding-top: 12px;
  margin-top: 32px;
  border-top: 1px solid rgba(255, 255, 255, .10);
}

.toggleWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.toggleWrapper input {
  position: absolute;
  left: -99em;
}

.toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 56px;
  height: 24px;
  background: #d8d8d8;
  border-radius: 30px;
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  margin-bottom: 0;
  margin-top: 2px;
  font-weight: 700;
}

.toggle:before, .toggle:after {
  position: absolute;
  line-height: 24px;
  font-size: 10px;
  z-index: 2;
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.toggle:before {
  content: "OFF";
  left: 4px;
  color: #7b7b7b;
}

.toggle:after {
  content: "ON";
  right: 4px;
  color: #fff;
}

.toggle__handler {
  display: inline-block;
  position: relative;
  z-index: 1;
  background: #fff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: 1px;
  left: -14px;
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

input:checked + .toggle {
  background: #ea7a22;
}

input:checked + .toggle:before {
  color: #fff;
}

input:checked + .toggle:after {
  color: #ea7922;
}

input:checked + .toggle .toggle__handler {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  border-color: #fff;
}

.type-button-fix {
  -webkit-appearance: unset !important;
}

.request-type-package {
  border: unset !important;
  padding: 0 !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.page-link {
  padding: 0.2rem .75rem;
}

.nav-pagination {
  float: right;
}

/* DatePicker */
.custom-datepick-add-m-p {
  display: inline-flex;
  flex-direction: column;
  width: 50%;
}

.end-time input, .start-time input {
  /* color: red !important; */
  width: 100% !important;
  line-height: 1.5;
  padding: .375rem .75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* CSS Document */

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 99999999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.content-loading{
  position:absolute;
  width: calc(100% - 15px);
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
  display:none;
}

.spinner-grow , .spinner-border {
  align-self: center;
  margin: auto;
  position: absolute;
  z-index: 99999999;
  height: 4em !important;
  width: 4em !important;
  overflow: visible;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

table .sorting {
  cursor: pointer;
  background-image: url(../images/sort/sort_both.png) !important;
  background-repeat: no-repeat;
  background-position: center right;
}

table .sorting_asc {
  cursor: pointer;
  background-image: url(../images/sort/sort_asc.png) !important;
  background-repeat: no-repeat;
  background-position: center right;
}

table .sorting_desc {
  cursor: pointer;
  background-image: url(../images/sort/sort_desc.png) !important;
  background-repeat: no-repeat;
  background-position: center right;
}

.spinner-border-sm {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: .15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.form-group.required {
  margin-bottom: 0rem !important;
}

.text-xs {
  font-size: 11px;
}

.form-group .spinner-border-sm {
  top: 0.5rem;
  right: 0.5rem;
}

.required .spinner-border-sm {
  right: 1rem;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.text-blink {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.select-sm .css-1ep9fjw,.select-sm .css-1uq0kb5{
  padding: 5px;
}
.select-sm .css-vj8t7z,.select-sm .css-2o5izw,.select-sm .css-162g8z5{
  min-height:30px
}

.react-dual-listbox button, .react-dual-listbox select{
  line-height: 2.5;
}

.header-after-login-wrapper #search-box .search-box-res{
  -webkit-transition: height 1s; /* For Safari 3.1 to 6.0 */
  transition: height 1s;
}


.header-after-login-wrapper .spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.header-after-login-wrapper .spinner > div {
  background-color: #333;
  height: 100%;
  width: 5px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.header-after-login-wrapper .spinner .rect1, .header-before-login-wrapper .spinner .rect1{
  /*-webkit-animation-delay: -1.1s;*/
  /*animation-delay: -1.1s;*/
  background: #C6E6F1;
}

.header-after-login-wrapper .spinner .rect2, .header-before-login-wrapper .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
  background: #C6E6F1;
}

.header-after-login-wrapper .spinner .rect3, .header-before-login-wrapper .spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
  background: #C6E6F1;
}

.header-after-login-wrapper .spinner .rect4, .header-before-login-wrapper .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  background: #C6E6F1;
}

.header-after-login-wrapper .spinner .rect5, .header-before-login-wrapper .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: #C6E6F1;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}

.header-after-login-wrapper #search-box .search-box-wrapper, .header-before-login-wrapper #search-box .search-box-wrapper {
  position: absolute;
  width: 100%;
  z-index: 1000
}
.header-after-login-wrapper #search-box .search-box-res, .header-before-login-wrapper #search-box .search-box-res{
  margin: auto;
  background: white;
  border: 1px solid rgba(0, 0, 0, .1);
}

.header-after-login-wrapper #search-box .news-box-search, .header-before-login-wrapper #search-box .news-box-search{
  /*flex-direction: row;*/
  /*display: flex;*/
  margin-bottom: 10px;
}

.header-after-login-wrapper #search-box .help-box-search, .header-before-login-wrapper #search-box .help-box-search{
  /*flex-direction: row;*/
  /*display: flex*/
}

/*.header-after-login-wrapper #search-box .help-box-search .search-news-item-text, .header-before-login-wrapper #search-box .help-box-search .search-news-item-text{*/
/*  color: #ea7b22;*/
/*}*/

.header-after-login-wrapper #search-box .box-title, .header-before-login-wrapper #search-box .box-title{
  width: 25%;
}

.header-after-login-wrapper #search-box .search-box-res .category-title, .header-before-login-wrapper #search-box .search-box-res .category-title{
  color: #505050;
  font-weight: bold;
  /*font-style: italic;*/
  font-size: 15px;
}
.header-after-login-wrapper #search-box .news-box-search ul, .header-before-login-wrapper #search-box .news-box-search ul{
  /*border-left: 2px solid #C6E6F1;*/
  width: 100%;
}

.header-after-login-wrapper #search-box .help-box-search ul, .header-before-login-wrapper #search-box .help-box-search ul{
  /*border-left: 2px solid #ea7b22;*/
  width: 100%;
}

.header-after-login-wrapper #search-box .line-horizontal, .header-before-login-wrapper #search-box .line-horizontal{
  width: 100%;

}

.header-after-login-wrapper #search-box .search-news-item-text, .header-before-login-wrapper #search-box .search-news-item-text{
  color: #C6E6F1;
  font-weight: bold;
}

.header-after-login-wrapper #search-box .search-news-item-text:hover, .header-before-login-wrapper #search-box .search-news-item-text:hover{
  text-decoration: underline ;
}


.header-after-login-wrapper #search-box .search-news-item p, .header-before-login-wrapper #search-box .search-news-item p{
  font-size: 13px;
}

.header-before-login-wrapper{

}
/*header.affix{*/
/*  padding-top: 10px;*/
/*  padding-bottom: 10px;*/
/*}*/


.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.row{
  margin-left: auto !important;
  margin-right: auto !important;
}

.menu-y li a.active {
  font-weight: 600;
}

.container.row {
  padding-top: 24px;
  min-height: 90vh;
}

.MuiFormLabel-root.Mui-focused{
  /*color: #00918D !important;*/
  color: #EA7B22 !important;
  /*color: #C6E6F1 !important;*/
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #C6E6F1 !important;
  /*border-color: #EA7B22 !important;*/
}
.modal .btn-close:hover{
  background-color: #EA7B22;
}
.modal .btn-close:hover .close{
  color: #fff;
}

.color-header-table{
  background-color: #C6E6F1;
  color: #fff;
}

.MuiTableCell-head {
  color: #fff !important;
  background-color: #C6E6F1;
}

.MuiTableCell-root{
  border: 0.5px solid rgba(224, 224, 224, 1);
  padding: 14px !important;
}

.align-right{
  text-align: right;
}
.align-center{
  text-align: center;
}
.align-left{
  text-align: left;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #C6E6F1 !important;
}
.input-Modal {
  padding-top: 15px;
}
.MuiButton-label {
  font-size: 12px;
}
.icon-table {
  padding-right: 35px;
  color: #C6E6F1;
}

.img-lang{
  width: 25px;
  cursor: pointer;
}

.container-wrapper{
  display: flex;
}


.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.borderDash {
  border: dashed;
  border-width: 0.5px;
  padding: 1px;
  border-radius: 5px;
}

.cardChooseService {
  text-align: center;
  margin-bottom: 20px;
//min-width: 300px;
}


.cardServicePark {
  width: 22%;
  margin-top: 10px;
  text-align: center;
}

.borderDashedSupervisor {
  border: dashed;
  border-width: 0.5px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  border-radius: 5px;

}

.uppercase {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;

}

.pricService {
  width: 80%;
  margin-top: 8px;
  border: groove;
  border-width: 0.5px;
  border-radius: 5px;
}
.format-currency{
  color: #5d78ff;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}
.titlePage{
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.service-name{
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}
.align-center{
  text-align: center;
}

.h-120 {
  height: 120px;
}

@media only screen and (min-width: 400px) {
  .w-120 {
    width: 400px;
  }
}

.buttonSearch {
  height: 36px;
  text-transform: initial;
}



.item-selected{
  border: 2px solid #49cc90 !important;
  background-color: #E8F6F0 !important;
  border-radius: 10px;
  padding: 8px !important;
}

.item-unselected{
  border: 1px solid #b3cde0;
  background-color: #fff;
  border-radius: 10px;
  padding: 8px !important;
}

.font-14 {
  font-size: 14px !important;
}
.font-16 {
  font-size: 16px !important;
}
.table-data thead > tr > th {
  /*border-color:#C6E6F1;*/
  /*border-bottom-width:1px;*/
  /*border-top-width:1px;*/
  font-size:14px;
  text-align:center;
}

.table-data tbody > tr > td {
  font-size:13px;
  text-align:center;
}

.text-link {
  cursor: pointer;
  color: #C6E6F1;
}
.text-link:hover {
  color: #ea7b22;
}
.width-text{
  width: 80px !important;
}

.service-plan-box-content{
  min-height: 240px;

}

.border-card-plan-small{
  background: #0DA9D329;
}

.service-plan-card{
  border-radius: 4px !important;
  border: 1px solid #C6E6F1;
  min-height: 300px;
  background: #0DA9D329 !important;
  /*cursor: pointer;*/
}

.hide-div{
  display: none;
}
.color-blue{
  color: #C6E6F1;
}

.table{
  margin-bottom: 0;
}

.btn.btn-action  a {
  color: #000000;
}

.btn.btn-action i {
  font-weight: bold;
}

.btn:disabled {
  font-weight: normal;
  opacity: .30;
}

.table-wrapper{
  /*margin-top: 15px;*/
  margin-bottom: 15px;
  overflow-x: auto;
}

.col-pl-0{
  padding-left: 0 !important;
}

.pagination-right{
  display: flex;
  /*justify-content: right;*/
  /*text-align: right;*/
  width: 100%;
  margin-bottom: 15px;
}

.pagination-left {
  display: flex;
  justify-content: left;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}

.button-func-upper-table{
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.btn-close .close span {
  position: absolute;
  top: -2px;
  left: 12px;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #C6E6F1 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #C6E6F1 !important;
}


.comment-line{
  /*line-height: 50px;*/
  /*min-height: 50px;*/
  margin-bottom: 10px;
}

textarea {
  resize: none;
}

.img-h120{
  width: auto;
  height: 120px;
}

.img-wh120{
  width: 120px;
  height: 120px;
}

.img-h160{
  width: auto;
  height: 160px;
}

.img-mh200{
  width: auto;
  min-height: 240px;
}

.img-h200{
  width: auto;
  height: 200px;
}

.img-wh120-wrapper{
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.line-height-30{
  line-height: 30px;
}
.content-wrapper{
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.complete-profile-wrapper {
  overflow: visible !important;
}

.button-filter-wp{
  position: relative;
  min-height: 40px;
}

.button-filter-wp .button-filter{
  position: absolute;
  bottom: 0;
  height: 35px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  color: #000;
  background-color: #C6E6F1
}
.MuiStepIcon-text{
  fill: #000 !important;
}


.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.suggest-wrapper{
  position: absolute;
  background: white;
  z-index: 9999;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 4px;
  width: 95%;
}
.suggest-wrapper .suggest-box {
  box-shadow: 0px 0px 6px 0px #888888;

}
.suggest-wrapper .suggest-box .suggest-item{
  padding: 5px 10px;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}
.suggest-wrapper .suggest-box .suggest-item:hover{
  font-weight: bold;
  background-color: #bfe8e2;
}

.profile-wrapper {
  background-color: transparent;
  box-shadow: none !important;
  border: none;
}

.min-w100{
  min-width: 100%;
}

.badge{
  font-size: 100%;
}

.card-header{
  box-shadow: none !important;
}

.text-align-right{
  text-align: right;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #5bafce !important;
}
.PrivateTabIndicator-colorPrimary-61 {
  background-color: #5bafce !important;
}

.img-logo-wrapper{
  background-size: contain;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 15%;
  /*background-position: 50%;*/
}
.img-ava-wrapper{
  width: 140px;
  height: 140px;
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  position: relative;
}
.change-logo-icon{
  /*position: absolute;*/
  bottom: 0;
  right: 0;
  font-size: 22px;
  border: 1px solid;
  border-radius: 4px;
  padding: 2px 4px;
  background-color: #fff;
  cursor: pointer;
  margin-left: 60%;
  margin-top: 5px;
}
.change-avt-icon{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 22px;
  border: 1px solid;
  border-radius: 4px;
  padding: 2px 4px;
  background-color: #fff;
  cursor: pointer;
}

.change-avt-icon:hover, .change-logo-icon:hover{
  background-color: #ea7b22;
  /*color: #57afce;*/
  color: #fff;
}

.fa-check-circle{
  color: #08d008;
}

.icon-delete-branch {
  position: absolute;
  text-align: center;
  /* display: flex; */
  /* vertical-align: middle; */
  right: 8px;
  top: -6px;
  width: 34px;
  height: 34px;
  padding: 9px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
}

.icon-delete-branch:hover {
  background: #c6e6f1;
}

/*height*/

.h-350{
  height: 350px;
}


/*font*/

.font-size-xxl{
  font-size: -webkit-xxx-large;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.input-money{
  text-align: right;
}
.MuiCard-root {
  overflow: initial !important;
}

.btn-secondary, .btn-warning, .btn-info {
  border-radius: .25rem !important;
  padding: 6px 14px !important;
  border: 1px solid !important;
}

.news-item{
  border: 1px solid #bbbbbb;
  border-radius: 4px;
}

.media-body .news-title{
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.button-rounded{
  border-radius: 50% !important;
  padding: calc(0.5em - 1px) calc(0.75em - 1px) !important;
  /*color: #fff !important;*/
}
.button-rounded:hover{
  background-color: #57afce !important;
  border-color: #57afce !important;
  color: #fff !important;
}

.footer-news-item{
  align-items: center;
}

.permission-box{
  height: 300px;
  overflow-y: auto;
  padding: 10px 0;
}

.permission-item{
  padding: 5px 15px;
  /*cursor: pointer;*/
}
.permission-item:hover{
  cursor: pointer;
  background-color: #C6E6F1;
}

.permission-item .permission-item .fas.fa-plus-square{
  color: green;
}
.permission-item .permission-item .fas.fa-minus-square{
  color: red;
}

.content-side-bar {
  max-height: 0;
  overflow: hidden;
  /*transition: max-height 0.5s ease-out !important;*/
  transition: max-height 0.8s ease-out !important;
  display: block !important;
}

.content-side-bar-active {
  max-height:500px;
  /*transition: max-height 0.5s ease-out !important;*/
  transition: max-height 0.9s ease-out !important;
  display: block !important;
}

.icon-arrow-side-bar {
  /*margin-left: 85px !important;*/
  font-size: 14px !important;
  font-weight: 600 !important;
}

.bg-green {
  background-color: #85c525;
}
.color-white {
  color: #fff;
}

.area-content-modal {
  border: 1px dashed #21b3d6;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 6px;
}
.area-content-modal .title-legend {
  position: absolute;
  left: 40px;
  background: #fff;
  padding: 0px 4px;
}

.badge {
  font-size: 11px !important;
}

.dropzone {
  height: 100% !important;
}
.container-upload {
  height: 100%;
}

.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}

.icon-side-bar {
  padding-right: 4px !important;
  padding-left: 0px !important;
  font-weight: 600 !important;
  text-align: center;
  width: 30px;
  margin-right: 0px !important;
}

.table-admin-dashboard {
  font-size: 12px !important;
}

.table-data.table-admin-dashboard thead > tr > th {
  font-size: 12px !important;
  padding: 10px 2px;
}

.icon-circle {
  border-color: #000 !important;
  width: 37px !important;
  height: 36px !important;
}
.icon-circle:hover {
  border-color: #57afce !important;
}
button.btn.sign-in-btn, button.btn.sign-up-btn.bg-green.color-white.hide-on-tablet {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ck-editor__editable {
  min-height: 200px !important;
}
