.art-time {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.art-time div {
    display: flex;
    align-items: center;
}

.art-time div svg {
    vertical-align: baseline;
}

.ob-article {
    padding: 50px 15px;
    background-color: white;
}

.art-content {
    text-align: justify;
}

.art-image img {
    width: 100%;
    height: auto;
    display: block;
    min-height: 350px;
}

.art-side-title {
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    border: none;
    max-width: 200px;
    text-align: left;
    min-width: fit-content;
}

img.img-art-side {
    height: 200px;
    background: grey;
    display: block;
}

.ob-article .comments {
    text-align: left;
    padding: 30px 15px;
    border-top: 1px solid #424242;
}

.ob-article .comments textarea {
    min-height: 200px;
    border-color: #ced4da !important;
    border-radius: 4px;
    padding: 10px;
}

.ob-article .comment-input-infor {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.ob-article .comment-input-infor .form-group {
    max-width: 300px;
}

.pd-0 {
    padding: 0;
}

@media screen and (max-width: 600px) {
    .ob-article h3 {
        font-size: 20px !important;
    }
}

.img-with-text {
    position: relative;
    display: block;
    width: 100%;
    height: 50%;
    overflow: hidden;
}

.img-with-text.hot-news-1 {
    height: 575px;
}

.img-with-text img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    transition: transform 0.4s ease, opacity 0.4s;
    transition-timing-function: ease !important;
}

.img-with-text .text-layer {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 20;
    background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 1)
    );
    display: flex;
    align-items: flex-end;
    color: white;
    padding: 10px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
}

.img-with-text .text-layer:hover {
    /*background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.6));*/
    transition: all .3s;
    -webkit-transition: all .3s;
}
