.partner-summary {
  border-bottom: 1.6px solid rgba(189, 215, 255, 0.75);
  padding: 32px 40px;
}
.avt-part {
  text-align: center;
  padding-left: 30px;
}

.partner-description {
  text-align: left;
  padding: 20px 40px;
}
.stars svg {
  height: 20px;
  width: 20px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  fill: #ffc107;
}
@media screen and (max-width: 599px) {
  .partner-summary h4 {
    width: 100% !important;
    text-align: center !important;
  }
}
