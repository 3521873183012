.icon-paging {
    margin-top: 8px;
    font-size: 22px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.icon-paging:hover {
    color: #1b90bd;
}

.select-paging {
    width: 60px !important;
    height: 28px
}

.nav-tabs .nav-link.active {
    color: #1b90bd;
    background-color: #fafafa;
    border-color: #1b90bd #1b90bd91 #fff;
    border-radius: 0;
}
