.step3-search {
  flex-wrap: wrap;
}
.step3-search .search-input {
  max-width: 422px;
  margin-bottom: 0;
  width: 100%;
}
.step3-search .btn-search {
  background-color: #f5da66;
  border-radius: 8px;
  border-color: #f5da66 !important;
  color: #4e4520 !important;
  width: 190px !important;
  height: 50px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 48px;
}
.step3-search .btn-search:hover {
  box-shadow: 3px 3px 6px #ddd;
}

.step3-search .btn-refresh {
  border-color: #8697a3 !important;
  color: #062942;
  border-radius: 8px;
  width: 190px !important;
  height: 50px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 48px;
  padding: 0 15px;
}
.step3-search .btn-refresh:hover {
  box-shadow: 3px 3px 6px #ddd;
}

.step3-item {
  padding: 11px 15px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  box-shadow: 6px 8px 0px #EFF3F5;
  border: 2px solid #d0d5e3;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
}

.step3-item::after {
  content: '';
  display: block;
  height: 11px;
  width: 100%;
  background-color: #f8efb4;
  position: absolute;
  bottom: 0;
  left: 0;
}
.step3-item:hover {
  border-color: #ffc107;
}

.step3-item img.step3-item-img {
  width: 160px;
  height: 160px;
  background-color: grey;
  border-radius: 50%;
  border: none;
  margin-top: 30px;
}

.step3-item .sup-name {
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
}

.step3-item .rating svg {
  height: 25px;
  width: 25px;
  margin-top: 10px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  fill: #ffc107;
}

.step3-item .sup-skills {
  margin-top: 25px;
  margin-bottom: 16px;
  text-align: center;
  font-size: 20px;
  color: #0f244e;
}

.btn-readmore {
  color: #0c7fe2;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 18px;
  transition: all 0.3s;
}

.btn-readmore:hover {
  color: #ffc107;
}

.step3-item .sup-skills .skill-title {
  font-style: italic;
  color: #0f244e;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 5px !important;
}

.step3-item .sup-skills .skill-des {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 20px;
  color: #0f244e !important;
}
.des-experience{
  min-height: 210px;
}
.sup-location {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  background: transparent;
  color: #062942;
  text-align: left;
  text-indent: 20px;
  font-size: 13px;
  font-size: 20px;
}

.step3-search .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(48px, -9px) scale(1);
}

.stepper-content .pagination-right {
  justify-content: flex-end;
}

.stepper-content .pagination-right .page-link {
  padding: 10px;
  width: 40px;
  text-align: center;
  margin-right: 10px !important;
  font-size: 16px;
}

.stepper-content .page-item.active .page-link {
  color: #0c7fe2;
  border-color: #0c7fe2;
  background-color: transparent;
}

.step3-search .MuiOutlinedInput-input {
  font-size: 17px;
  font-family: 'Montserrat';
  padding-left: 24px !important;
}

.step3-search .MuiOutlinedInput-root {
  height: 50px;
  font-family: 'Montserrat';
}

.step3-search .PrivateNotchedOutline-legendNotched-4 {
  max-width: 0.01px;
}

.step3-search .MuiOutlinedInput-notchedOutline {
  border-color: #8697a3;
}
.step3-search .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #8697a3;
}

.step3-content-list {
  margin: 0 -70px !important;
}

.step3-content-list .col-md-4 {
  padding: 0 70px;
}

.step3-content {
  margin-top: 40px;
}

.btn-bottom.btn-step-3 {
  margin-top: 75px;
  margin-bottom: 90px;
}

@media screen and (max-width: 1600px) {
  .step3-search .search-input {
    max-width: 340px;
  }

  .step3-content-list {
    margin: 0 -16px !important;
  }

  .step3-content-list .col-md-4 {
    padding: 0 16px;
  }
}

@media screen and (max-width: 992px) {
  .step3-search .search-input {
    max-width: 329px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .step3-content-list {
    margin: 0 0 !important;
  }

  .step3-search .search-input {
    max-width: 50%;
    margin-bottom: 20px;
    margin-left: 16px;
  }

  .step3-search .btn-search,
  .step3-search .btn-refresh {
    width: 150px !important;
  }

  .btn-search-refresh {
    margin-left: 16px;
  }

  .stepper-content .pagination-right .page-link {
    padding: 6px;
    width: 35px;
    margin-right: 5px !important;
  }

  .stepper-content .pagination-right {
    padding-right: 11px;
  }

  .btn-bottom.btn-step-3 {
    margin: 40px 0;
  }
}
@media screen and (max-width: 600px) {
  .btn-search-refresh {
    margin-top: 15px;
  }
  .step3-search .search-input {
    max-width: unset !important;
  }
}




