.list-sup-search-content {
  transform: none !important;
}
.banner-search {
  padding: 30px 0;
  background-color: #bdd7ff;
}

.banner-search .selection {
  min-width: 300px;
}
.sup-count {
  font-weight: bold;
}
.list-sup-item {
  border-radius: 4px;
}
.sup-desc p {
  margin-bottom: 2px;
}
.supervisor-avt {
      background: #bdd7ff;
      border-radius: 50%;
      /*max-width: 100%;*/
      /*max-width: 120px;*/
      /*min-width: 120px;*/
      /*min-height: 120px;*/
      width: 160px;
      height: 160px;
      overflow: hidden;
      text-align: center;
      padding: 0.8px;
}

.list-sup-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
    /*margin-bottom: 20px;*/
}

.list-sup-content ul li:before {
    content: "+";
    margin-right: 4px;
}
.pdx-30px {
    padding-left: 30px;
    padding-right: 30px;
}

.pdy-15px {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pd-15px {
    padding: 15px;
}

.homepage-supervisor-item{
    border-left: 1px solid #D0D5E3;
    height: 100%;
}
