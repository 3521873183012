
.form-Search{
    width: '100%';
    padding-left: 4px;
    overflow: hidden;
}

/*.tableList {*/
/*    margin-bottom: 20px;*/
/*    overflow: hidden;*/
/*}*/

.border-1-s {
    border: 1px solid #ccc;
}

.supervisor-img-avt {
    text-align: center;
    width: 130px;
    height: 130px;
    border-radius: 50%;
}

.supervisor-img-wrapper{
    height: 160px !important;
}

.supervisor-card-item{
    min-height: 450px;
}

.supervisor-card-item:hover{
    border: 2px solid #49cc90 !important;
    background-color: #E8F6F0 !important;
    border-radius: 10px;
}

.priority-supervisor{
    background-color: #eae4e4 !important;
}

@media(max-width: 599px) {
    .supervisor-img-avt {
        text-align: center;
        /*width: 65% !important;*/
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {
    .item-card{
        min-height: 335px !important;
    }
}

@media (max-width: 1600px) {
    .item-card{
        min-height: 350px !important;
    }
}

@media (max-width: 1200px) {
    .item-card{
        min-height: 480px !important;
    }
}

.my-scroll::-webkit-scrollbar-track
{
    background-color: #f1f1f1;
}

.my-scroll::-webkit-scrollbar
{
    width: 4px;
    background-color: #ccc;
}

.my-scroll::-webkit-scrollbar-thumb
{
    background-color: #888;
}
