.App {
    text-align: center;
    color: #2f3b59;
    font-family: Segoe UI-MONOSPACE;
    background-color: #f8f8f8;
}

.App .card{
    box-shadow: none;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 1600px) {
    .container {
        max-width: 1400px !important;
    }
}

@media screen and (min-width: 1920px) {
    .container {
        max-width: 1720px !important;
    }
}

/* define color */

.color-main {
    color: #0c7fe2 !important;
}

.bg-main {
    background-color: #0c7fe2 !important;
}

.color-main-dark {
    color: #133476 !important;
}

.bg-main-dark {
    background-color: #133476 !important;
}

.color-green {
    color: #85c525 !important;
}

.bg-green {
    background-color: #85c525 !important;
}

.color-secondary {
    color: #374f61 !important;
}

.bg-secondary {
    background-color: #374f61 !important;
}

.color-white {
    color: #fff !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-gradient-main {
    background: linear-gradient(to right, #3ba5f7, #0075dd) !important;
}

.show-on-tablet {
    display: none;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .hide-on-tablet {
        display: none;
    }

    .show-on-tablet {
        display: block;
    }
}

.box-shadow-main {
    /*box-shadow: 0 0 10px 0px #2f3b59;*/
    box-shadow: 0 0 5px 0px #E3EAEE;
}
.box-shadow-secondary {
    box-shadow: 0 0 4px #424242;
}

.box-border-radius{
    border: 1px solid #DBDEE0;
    border-radius: 4px
}


.img-small {
    width: 20px;
    height: 20px;
}

.app-title {
    text-transform: capitalize;
    font-weight: bold;
    color: #133476;
    font-size: 20px !important;
}
.app-separator {
    margin-top: 20px !important;
    /*margin-bottom: 20px !important;*/
}

@media screen and (min-width: 600px) {
    .app-title {
        font-size: 20px !important;
    }
}

@media screen and (min-width: 960px) {
    .app-title {
        font-size: 24px !important;
    }
}

@media screen and (min-width: 1024px) {
    .app-title {
        font-size: 28px !important;
    }
}

@media screen and (min-width: 1280px) {
    .app-title {
        font-size: 2rem !important;
    }
}
@font-face {
    font-family: 'montserrat';
    src: local('montserrat'),
    url('../fonts/monsterat/MONTSERRAT-REGULAR.TTF') format('truetype');
}
/*@font-face {*/
/*    font-family: 'montserrat';*/
/*    src: local('montserrat'),*/
/*    url('../fonts/monsterat/MONTSERRAT-REGULAR.TTF') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'montserrat';*/
/*    font-weight: 700;*/
/*    src: local('montserrat'),*/
/*    url('../fonts/monsterat/MONTSERRAT-SEMIBOLD.TTF') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'montserrat';*/
/*    font-weight: 900;*/
/*    src: local('montserrat'),*/
/*    url('../fonts/monsterat/MONTSERRAT-BOLD.TTF') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'montserrat';*/
/*    font-weight: 300;*/
/*    src: local('montserrat'),*/
/*    url('../fonts/monsterat/MONTSERRAT-LIGHT.TTF') format('truetype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'montserrat';*/
/*    font-weight: 100;*/
/*    src: local('montserrat'),*/
/*    url('../fonts/monsterat/MONTSERRAT-THIN.TTF') format('truetype');*/
/*}*/

.bg-light-blue {
    background-color: #eff8fe;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.btn-close:focus { outline: none; }
.btn-close .close:focus { outline: none; }
.customer-rate.container-fluid.app-separator{

}

.search-panel.back.container,
.company-description.app-separator.container,
.company-description.app-separator.container,
.company-professional.app-separator.container,
.infrastructure.app-separator.container,
.hot-article.app-separator.container {

}

.homepage-wrapper .homepage-container{
    width: 100%;
    padding-right: 15px !important;
    padding-left: 15px !important;
    /*margin-right: auto !important;*/
    /*margin-left: auto !important;*/
}

.homepage-wrapper .card:hover{
    cursor: pointer;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.list-sup-wrapper{
    margin-top: -15px;
    margin-bottom: -15px;
}

.home-news-wrapper{
    margin-top: -15px;
    margin-bottom: -15px;
}

.detail-sup-wrapper{
    margin-top: -15px;
    margin-bottom: -15px;
    font-family: montserrat;
}
.text-bold {
    font-weight: bold;
}

.article-time{
    margin-bottom: 1rem;
}

.border-homepage{
    border: 1px solid #D0D5E3;
    box-shadow: 3px 5px 5px 1px #EFF3F5;
}

.dashboard-info-item{
    height: 120px;
    text-align: center;
    padding: 10px;
    /*border: 1px solid;*/
    border-radius: 4px;
}

.dashboard-title-item{
    font-size: 1.25rem;
    font-weight: bold;
}

.dashboard-content-item{
    font-size: 1.5rem;
    font-weight: bold;
}

.btn-notification{
    border-radius: 50%;
    border: 1px solid #2DA5FF;
    color: #2DA5FF
}

.notification-box-wrapper{
    position: absolute;
    background-color: #fff;
    font-size: 14px;
    border-radius: 8px;
    /*left: -158px;*/
    /*box-shadow: 0 6px 6px rgba(0,0,0,.175);*/
    top: 45px;
    left: -50px;
    border: 1px solid rgba(0,0,0,.15);
    min-height: 100px;
    min-width: 300px;
    z-index: 1234;
    /*display: none;*/
}

.notification-item{
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    cursor: default;
    background-color: #fff;
}
.notification-item:hover{
    background-color: #edf2fa;
    transition: background-color 0.2s ease-in;
}

.notification-title{
    font-weight: bold;
}

.notification-content{
    padding-left: 8px;
}

.notification-scroll{
    display: flex;
    align-items: center;
    font-weight: bold;
    color: white;
}

.vertical-align-center {
    vertical-align: middle !important;
}

.card.w-100.article-item {
    height: 500px;
}

.star-full {
    height: 22px !important;
    width: 22px !important;
}

.star-empty {
    margin-top: 2px !important;
    height: 24px !important;
    width: 24px !important;

}

.badge {
    font-size: 11px !important;
}

.icon-delete-relative-news{
    background-color: white;
    color: red
}

.icon-delete-relative-news:hover{
    background-color: red;
    color: white;
    cursor: pointer;
}

.relative-news-item{
    border-bottom: 1px solid #00000020;
}

/*sidebar*/

.sidebar-mobile-wrapper{
    display: none;
    /*height: 100%;*/
    height: auto;
    width: 100%;
    background-color: white;
    z-index: 99999 !important;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 1s;
    transition-timing-function: linear;
    border-bottom: 1px solid #00000020;
}

.show {
    display: block !important;
}

.sb-mb-header, .sb-mb-user{
    border-bottom: 1px solid #cccccc;;
}

.sb-mb-homepage-menu{
    border-bottom: 1px solid #cccccc;;
}

.sb-mb-homepage-menu-item a{
    color: #000000;
    padding: 1rem 1rem;
}

.label-item {
    margin-top: 8px;
}
