
.childrenContent {
    margin-bottom: 20px;
    padding: 16px;
    border: 2px solid #fafafa;
}


.formSearch{
    width: '100%';
    overflow: auto;
    padding-top: 8px;
    overflow: hidden;
    margin-bottom: 20px;
}

.tableList {
    margin-bottom: 20px;
    overflow: auto;
}

.labelInput{
    width: 130px !important;
}
.rowInput{
    margin-bottom: 20px;
}

.colInput {
    margin-bottom: 20px !important;
}

.fieldInput {
    width: 90% !important ;
}

@media(max-width: 480px) {
    .colInput {
        line-height: 40px;
    }
}
@media(min-width: 480px) {
    .colInput {
        display: inline-flex;
        line-height: 40px;

    }
}
.icon{
    font-size: 20px ;
}