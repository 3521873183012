.pd-8-input {
    padding: 8px !important;
}

.pd-4-input {
    padding: 0px 4px !important;
}

.hei-input {
    height: 30px !important;
}

.my-select option {
    height: 40px !important;
}
.my-select {
    border-color: #ccc;
}
.color-red {
    color: red;
}

.change-avt {
    cursor: pointer;
    width: 36px;
    display: inline-block;
    vertical-align: bottom;
    height: 36px;
    position: absolute;
    border-radius: 50%;
    background: #fafafa;
    top: 134px;
    line-height: 36px;
    text-align: center;
    /*right: 170px;*/
    padding-left: 1px;
}

.change-avt:hover {
    background: #C6E6F1;
    color: #fff;
}

.MuiCardHeader-root{
    border-bottom: 1px solid #0000001f;
}
