.logo {
    width: 7%;
    height: 10%;
}

/*.banner {*/
/*    width: 90%;*/
/*    max-height: 165px;*/
/*    margin-left: 12px;*/
/*}*/

.banner-1 {
    width: 100%;
}

.content {
    border: dashed;
    border-width: 0.5px;
    padding: 10px;
    background-color: #E6F6FE;
    margin-top: 30px;
}

.staff {
    text-align: center;
    margin-top: 10px;
}

.name-staff {
    text-transform: capitalize;
    font-weight: bold;
}

.footer {
    background-color: black;
    width: 100%;
    color: white;
}

.footer-name {
    font-size: 18px;
    font-weight: bold;
}


.title-1 {
    width: 93%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.title-2 {
    width: 93%;
    font-size: 20px;
    font-weight: bold;
}

.title-3 {
    font-size: 25px;
    font-weight: bold;
    /*margin-left: 350px;*/
    text-align: center;
}

.table1{
    width: 50%;
    padding-left: 100px;
}

.table2{
    width: 49%;
}

th {
    padding-left: 15px;
}

td {
    padding-left: 15px;
}

.headtable1 {
    background-color: #81D3F8;
}

.headtable2 {
    background-color: #FACD91;
}

.bodytable1 {
    background-color: #BBFFFF;
}

.bodytable2 {
    background-color: #FCE4C4;
}
