.pd-8-input {
    padding: 8px !important;
}

.pd-4-input {
    padding: 4px !important;
}

.hei-input {
    height: 28px !important;
}

.color-red {
    color: red;
}

.change-avt-1 {
    cursor: pointer;
    width: 36px;
    display: inline-block;
    vertical-align: bottom;
    height: 36px;
    /* position: absolute; */
    border-radius: 50%;
    background: #fafafa;
    top: 134px;
    line-height: 36px;
    text-align: center;
    margin-left: -25px;
    /* right: 170px; */
    opacity: 80%;
    margin-bottom: -12px;
}

.change-avt-1:hover {
    background: #1b90bd;
    color: #fff;
}

.h-36{
    height: 36px;
}
.size-font-err-date{
    font-size: 0.75rem;
}
