/*--------------------------------------- Responsive (MIN) ------------------------------------------*/
/*08/06/2020*/
/*.container {min-width: 100% !important;}*/
@media (min-width: 1200px) {
	/*.container {min-width: 1250px;}*/
	.main-mn {margin-left: 24px;}
	.main-mn .nav-item .nav-link {padding: 24px 10px;}
	.navbar-user .dropdown .nav-link {padding: 20px 16px;}
	.main-mn .nav-item.active .nav-link {color: #1bbda5;}

	.navbar {padding: 0;}

	.number-table .col-md-3 , .number-table .col-md-9 {width: auto; max-width: none; margin:0 15px;}

	.number-table .col-md-9 {flex-grow: 1; margin-left: 0; flex: auto; max-width: 802px}
	.textpage .number-table .col-md-9 {margin-right: 0; margin-left: 15px}
	.ads { display: block}

	.list-sim .wrap {background:none; box-shadow: none}

	.main-banner .carousel-item {height: 496px}
	/*.confirm-email-wrapper{*/
	/*	width: 1150px;*/
	/*}*/
}

@media (min-width: 990px) {

	.main-mn .dropdown-menu, .navbar-user .dropdown-menu {
		/*width: 660px;*/
		/* border-radius: 0; *//* border: 0; */background: #ffffff;margin-top: 0px;padding: 25px 32px;font-size: 14px;border-radius: 8px;left: -158px;box-shadow: 0 6px 6px rgba(0,0,0,.175);top: 85%;}
	.navbar-user .dropdown .nav-link {padding-right: 0}
	.main-mn .dropdown-menu .col-md-6:first-child {/* border-right: 1px solid rgba(0,0,0,.15); */}
  .main-mn .dropdown-menu{left: 0px}
	.main-mn .dropdown-menu:before, .navbar-user .dropdown-menu:before, .main-mn .dropdown-menu:after, .navbar-user .dropdown-menu:after {position: absolute;top: -6px;
    /*right: 198px;*/
    display: inline-block;content: "";border-top: 0;border-right: 6px solid transparent;border-bottom: 6px solid #ffffff;border-left: 6px solid transparent; z-index:10}
	.main-mn .dropdown-menu:after, .navbar-user .dropdown-menu:after {border-right: 8px solid transparent;border-bottom: 8px solid #ddd;border-left: 8px solid transparent;top: -8px;z-index:9;right: 33px;}
  .main-mn .dropdown-menu:after{right: auto;left: 30px}
	.navbar-user .dropdown-menu:before {left:initial; right: 36px }

	.nav-item.dropdown:hover .dropdown-menu {display: block}

	.navbar-user .dropdown-menu {width: auto;left: initial;right: 0;}
	.navbar-user .dropdown {/* padding: 6px 0; *//* margin-right: 10px; */}
	.nav-link.cart {padding: 12px 0;margin-top: 2px;position: relative;}

	.navbar-user .dropdown > a {font-size: 14px}

	.number-table .col-md-3 .wrap-col, .number-table .col-md-9 .wrap-col {padding:25px 10px}

	.navbar .navbar-user .btn-signup {border-radius: 30px;/* border: 2px solid #1bbda5; */color: #656565;font-size: 15px;padding: 5px 20px 6px 12px;/* margin-left: 10px; */font-weight: 600;}
}

@media (min-width: 768px) and (max-width: 990px) {
	/*.container {min-width: 760px}*/
	.filter-opt h5 a {pointer-events: none}
	.owl-dots {display: none}
}
@media (min-width: 576px) and (max-width: 768px) {
	/*.container {min-width: 580px}*/
	/*.container {min-width: var(--max-content-width);}*/
}

@media (min-width: 760px) {

  .dropdown-menu.dropdown-menu-mobile {
    display: none !important;
  }
}
@media (max-width: 990px) {
	.nav-item .dropdown {display: block}
	.navbar-toggler {padding: 0; border: 0}

	.row.number-table {margin-left: 0; margin-right: 0}

	.main-mn .dropdown-menu {background: none;border: none;border-radius: 0;padding-top: 20px;}

	.navbar .navbar-user .btn-signup {margin-top: 10px}

	.main-mn .nav-item .nav-link {clear: both;text-align: center;border-bottom: 1px solid #f5f5f5;}
	.main-mn .nav-item .nav-link:before {/*content:"+"*/;display:inline-block;font-size: 24px;text-align:center;line-height: 23px;color: #00000070;background: #ffffff;width: 24px;height: 24px; float: right}

	.main-mn .nav-item.dropdown.show > .nav-link:before {content: "-";}
	.main-mn .dropdown-menu .col-md-6 {margin-bottom: 20px}
	.descript {display: none}

	.detail-ct img {max-width: 100%}

	.main-banner .carousel-item {height: 400px}
	.carousel-item.opt-1 {padding: 12px 0}
}
@media (max-width: 760px) {
  .dropdown-menu.dropdown-menu-pc{
    display: none !important;
  }
  .dropdown-menu.dropdown-menu-mobile{
    display: block;
  }
  .nav-tabs.sidebar{
    display: none;
  }
  .nav-tabs.sidebar.sidebar-mobile{
    display: block;
  }
}

@media (max-width: 768px) {
	header .container nav {padding-left: 0; padding-right: 0}
	.pricing1, .faqs, .manage-account {padding-top: 32px;padding-bottom: 32px;}
	.form-row {margin-left: 0; margin-right: 0}
	.form-row .pl-md-0 {padding-left: 0}
	.form-row .pr-md-0 {padding-right: 0}
	.form-row .px-md-0 {padding-left: 0; padding-right: 0}
	.form-group.form-row.mb-0 {margin-bottom: .7rem!important}
	.other-news {margin-bottom: 0;padding-top:  0;padding-bottom: 16px;border-bottom: 1px solid rgba(0,0,0,.1);}
	.btn-secondary, .btn-warning, .btn-info {margin-bottom: 8px;min-width: 75%;margin-left: 0!important;margin-right: 0!important;border-radius: .25rem;}
	.form-group .col-sm-4.text-right {text-align: inherit !important}

	.add-position {width: 49.3%; border: 1px solid #ccc; margin-bottom: 5px;}
	.form-checkbox-collapse2 .btn {border: none;display: block;padding: 10px;background: rgb(192, 194, 195);margin: 0;}
	.form-checkbox-collapse2 .img-check {background: none}
	.navbar .navbar-user .btn-signup {margin-top: 10px;color: #171717;background: #eee;}

	.buying-code-mobile .advance label {display: block}
	.buying-code-mobile .col-md-11 > .form-group {margin-bottom: 0}

	.buying-code-mobile .advance .col-sm-4.text-right {text-align: center !important; margin-top: 20px}

	.filter-opt h5 a {display: flex; justify-content: space-between;}
	.filter-opt h5 a.collapsed:after {content:"\f10a";font-family: "Flaticon";font-size: 12px;transform: rotate(-90deg);margin-right: -12px;}
	.filter-opt h5 a:after {transform: rotate(90deg);content:"\f10a";font-family: "Flaticon";font-size: 12px;}

	.detail-ct {padding-bottom: 24px}
	.list-unstyled .media {margin-top: 12px}
	.viewest-box {padding-bottom: 24px}
	.filter .form-control {margin-bottom: 12px}
	.filter>[class*=col-] {padding-left: 0; padding-right: 0; text-align: center}
	.hide-v-m {display: none}
	/*.table {min-width: 960px}*/

	.modal-dialog-centered {min-height: auto}

	#profile {padding:16px 0 !important}
	#profile .row {margin-left: 0; margin-right:0}
	.change-avatar {bottom: 8px}
	.manage-account .tab-content .tab-pane#profile > .row > .col-md-3 .avatar {padding-top: 0;margin: 20px 0!important;}

	.main-mn {padding-top: 16px}
	.main-mn > li:first-child a {/* display: flex; *//* justify-content: space-between; */}
	.main-mn > li:first-child a span {align-self: center}
	.navbar-user .align-self-center {/* align-self: flex-start!important */}
	.navbar-user .align-self-center a.btn-search {background: none;padding-left: 0;margin-top: 0;padding-right: 0;}
	.navbar-user .dropdown-menu {display: block; border: none}
	.navbar-user .dropdown-menu a {font-weight: 400}


	.wcu .owl-prev:before {content:"\f109"; left: 0;}
	.wcu .owl-next:before {content:"\f108"; right: 0;}
	.owl-carousel.row {margin: 0}
	.testimonial-list.pt-4 {padding-top: 8px!important;}
	.testimonial-list .avatar, .testimonial-list .avatar img {width: 64px; height: 64px;}
	.testimonial-list .media {margin-top:12px}
	.services, .wcu, .partner {margin-top: 40px; padding-top: 32px;}
	.title-1st {margin-bottom: 0}

	.main-banner .bg-bn {bottom: 40%}
	.main-banner .carousel-item {height: 400px}
	.m-ct .call-to-action .btn-original {font-size: 20px}
	.main-banner h1 {font-size: 2rem}
	.m-ct .col-md-4 {margin-top: 0!important}
	.main-banner .carousel-indicators {bottom: -20px;justify-content: center;}
	.main-banner .carousel-indicators li, .owl-theme .owl-dots .owl-dot span {width: 8px; height: 8px; margin-left: 4px; margin-right: 4px;}
	.main-banner .opt-2 .ct-opt2, .main-banner .opt-2 .ct-opt2 .text-right {text-align: center!important}
	.main-banner .opt-2 .ct-opt2 {background: #00475b;height: 40%;}
	.main-banner .opt-2 .ct-opt2 .container {display: flex; align-items: center; height: 100%}
	.main-banner .opt-2 .ct-opt2 .row {align-items: center;height: 80%;}
	.main-banner .opt-2 .ct-opt2 h1 {margin-bottom: 12px}
	.main-banner .opt-1 .mb-5 {margin-bottom: 16px !important}

	h2.title-1st {font-size: 1.6rem;}

	footer {padding-top: 16px}
	/*footer .row {display: none}*/
	footer .row.copyright {display: flex;border-top: none;text-align: center;margin-top: 0;padding-top: 0;font-size: .9rem;}
	footer .row.copyright .text-right {text-align: center!important;margin-top: 8px;}
	footer .row.copyright .text-right img {width: 96px;}

}

@media (max-width: 640px) {
	.submit-btn {margin-right: 0!important; margin-left: 0!important}
	.submit-btn .btn {width: 100%; margin-bottom: 15px; margin-left: 0 !important; margin-right: 0 !important}
	.btn-secondary, .btn-warning, .btn-info {width: 100%;}
	.wcu .owl-nav, .services .owl-nav {display: none}
  .manage-account .tab-content .tab-pane#profile > .row > .col-md-3 .avatar {
    padding-top: 0;
    margin: 15px 0!important;
    width: 130px;
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 736px) {
  .manage-account .tab-content .tab-pane#profile > .row > .col-md-3 .avatar {
    padding-top: 0;
    margin: 15px 0!important;
    width: 35%;
  }
}

@media screen
and (max-width : 768px)
and (min-height : 900px)
and (max-height : 1025px) {
  .btn-select-plan{
    min-width: 50px;
    font-size: 13px;
    padding: 2px 10px;
  }
}

@media screen
and (min-width: 1600px){
	.container, .container-lg, .container-md, .container-sm, .container-xl {
		max-width: 1500px;
	}
}
@media screen and (min-width: 769px){
	.modal-content, .modal-card {
		width: 100% !important;
	}
}
@media screen
and (max-width: 500px)
and (orientation: portrait) {
	.container {
		padding: 0 !important;
	}
	.container.row{
		padding-top: 0 !important;
	}
	.tab-content.col-md-10{
		padding-right: 15px !important;
	}
}
