.borderDashedSupervisor {
    border: dashed;
    border-width: 0.5px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    border-radius: 5px;
}

.buttonSearch {
    height: 36px;
    text-transform: initial;
}
.border-1-s {
    border: 1px solid #ccc;
}

.supervisor-img-avt {
    text-align: center;
    width: 130px;
    border-radius: 50%;
}

.supervisor-img-wrapper{
    height: 160px !important;
}

.supervisor-card-item{
    min-height: 450px;
}

.supervisor-card-item:hover{
    border: 2px solid #49cc90 !important;
    background-color: #E8F6F0 !important;
    border-radius: 10px;
}

.priority-supervisor{
    background-color: #eae4e4 !important;
}

@media(max-width: 599px) {
    .supervisor-img-avt {
        text-align: center;
        /*width: 65% !important;*/
    }
}
