.home-news-wrapper {
    background: #f2f3f5;
}

.hot-news-box {
    max-height: 550px;
}

.img-with-text {
    cursor: pointer;
}

.img-with-text img:hover {
    transform: scale(1.2);
}

.hot-news-box .hot-news-title {
    position: absolute;
    bottom: 24px;
    text-transform: uppercase;
    cursor: pointer;
}

.hot-news-box .news-title-big {
    font-size: 2rem;
}

.hot-news-box .news-title-medium {
    font-size: 1.4rem;
}

.hot-news-box .info-time {
    font-family: Roboto;
    padding-left: 2px;
    color: #bbb;
    font-size: 11px;
}

.hot-news-box .hot-news-1 {
    overflow-x: hidden;
    position: relative;
    display: flex;
}

.hot-news-box .hot-news-2, .hot-news-box .hot-news-3 {
    position: relative;
    display: flex;
}

.hot-news-box .hot-news-1 .img-hot-news-1 {
    height: 100%;
}

.list-home-news {
    margin-bottom: 40px !important;
    background: #fff;
    /*border-top: 4px solid #1e3d59;*/
}

.list-home-category {
    margin-bottom: 40px !important;
    padding-left: 20px;
    /*background: #fff;*/
}

.list-home-category-area {
    margin-bottom: 40px !important;
    background: #fff;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 15px;
    /*border-top: 4px solid #1e3d59;*/
}

.list-home-category-area ul {
    margin-left: 20px;
    list-style: circle;
    margin-top: 20px;
}

.list-home-category-area ul li {
    list-style: circle;
    letter-spacing: 0.2px;
}

.list-home-category-area .news-category-title {
    font-size: 18px;
    color: #333;
    line-height: 28px;
    display: block;
    margin-bottom: 10px;
    margin-top: -4px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
}

.home-news-item-box {
    padding: 4px 8px;
    margin-left: 20px;
}

.news-tag-label {
    background-color: #55A9F6;
    color: #FFFFFF;
    width: 150px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px;
    font-size: 18px;
    font-weight: bold;

}

.home-news-item {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D0D5E3;
}

.home-news-ava {
    min-height: 150px;
    /* min-width: 400px; */
    width: 250px;
    max-height: 300px;
    /* background: linear-gradient(120deg, darkgrey, transparent); */
    padding-right: 0;
    overflow: hidden;
}

.home-news-ava a {
    width: 100%;
    height: 100%;
    display: block;
    /* margin-right: 20px; */
    overflow: hidden;
    position: relative;
    border: 1px solid #fafafa;
}

.home-news-item .news-title {
    font-size: 20px;
    color: #333;
    line-height: 28px;
    display: block;
    margin-bottom: 10px;
    margin-top: -4px;
    margin-right: 8px;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
}

.home-news-item .news-title:hover {
    /*color: #374370;*/
    color: #55a9f6;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.home-news-item .news-description {
    font-size: 14px;
    color: #555;
    line-height: 19px;
    margin-right: 8px;
}

.home-news-item .news-create-info {
    margin-top: 12px;
    line-height: 20px;
    position: absolute;
    bottom: -4px;
}

.home-news-item .news-create-info .create-by {
    font-size: 13px;
    color: #393c54;
    text-decoration: none;
}

.home-news-item .news-create-info .create-at {
    position: relative;
    font-size: 13px;
    color: #8e8e8e;
}
