@media only screen and (max-width: 600px) {
    .h-300{
        height: 100px !important;
    }
}
@media (min-width: 600px) {
    .mh-400{
        min-height: 540px !important;
    }
}

@media (min-width: 1000px) {
    .mh-400{
        min-height: 420px !important;
    }
}

@media (min-width: 1100px) {
    .mh-400{
        min-height: 350px !important;
    }
}
