
.MuiStepLabel-label.MuiStepLabel-active:hover {
    color: #3273dc;
    font-weight: 500;
    cursor: pointer !important;
}
.MuiStepIcon-root.MuiStepIcon-active:hover {
    color: #c6f1d9 !important;
    cursor: pointer !important;
}

.MuiStepLabel-label.MuiStepLabel-label:hover {
    color: #3273dc;
    font-weight: 500;
    cursor: pointer !important;
}
.MuiStepIcon-root.MuiStepIcon-root:hover {
    color: #c6f1d9 !important;
    cursor: pointer !important;
}

/*.MuiStepLabel-root:hover  .MuiStepLabel-label.MuiStepLabel-active {*/
/*    color: #3273dc;*/
/*    font-weight: 500;*/
/*    cursor: pointer !important;*/
/*}*/
/*.MuiStepLabel-root:hover .MuiStepIcon-root.MuiStepIcon-active {*/
/*    color: #c6f1d9 !important;*/
/*    cursor: pointer !important;*/
/*}*/

/*.MuiStepLabel-root:hover .MuiStepLabel-label.MuiStepLabel-label {*/
/*    color: #3273dc;*/
/*    font-weight: 500;*/
/*    cursor: pointer !important;*/
/*}*/
/*.MuiStepLabel-root:hover .MuiStepIcon-root.MuiStepIcon-root {*/
/*    color: #c6f1d9 !important;*/
/*    cursor: pointer !important;*/
/*}*/

