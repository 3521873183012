.btn-cancel-stepper {
  background-color: white;
  color: #0c7fe2;
  border: 1px solid #0c7fe2;
  border-radius: 50px;
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: 17px;
  min-width: 120px;
}

.btn-next-stepper {
  color: white;
  background-color: #0c7fe2;
  border: 1px solid #0c7fe2;
  border-radius: 50px;
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: 17px;
  min-width: 120px;
}

.stepper-title {
    font-weight: 600;
    color: #0c7fe2;
}

.stepper-router {
    color: #374f61;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.stepper-router a {
    color: #374f61;
}

.selected {
    border: 1px solid #12acff !important;
    box-shadow: 3px 5px 5px 1px #c8d7df !important;
    transition: box-shadow 0.2s, border 0.2s !important;
    background: #f4f4f4;
}
