

.height-description {
    height: 50px;
    /*padding-bottom: 50px;*/
}
.error-style {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 10px;
    color: crimson;
}
.border-index {
    margin-left: 50px;
    margin-right: 50px;
    padding-top:20px;
}
@media only screen and (min-width: 300px) {
    .title-style {
        font-size: 16px !important;
    }
    .size-card{
        width: 100% !important;
    }
    .height-title {
        height: 80px;
        padding-bottom: 30px !important;
    }
    .width-select {
        width: 90px !important;
    }
}

.width-info{
    width: 80px !important
}

.border-card-plan{
    border-radius: 4px !important;
    border: 1px solid #C6E6F1;
}
.border-card-plan-small{
     background: #0DA9D329;
}

.border-card-plan-small-white{
    background: #ffffff;
}

.service-plan-div:hover{
    /*cursor: pointer;*/
    /*background-color: #0DA9D329*/
}

.body-service-plan-card:hover{
    cursor: pointer;
    background-color: #eae4e4;
}

.height-content{
    height: 300px!important;
}

.service-plan-name{
    text-align: center;
    height: 70px!important;
    font-size: 16px;
}
