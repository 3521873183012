.btn-da-chuyen-khoan, .btn-can-tu-van {
    -webkit-flex-direction: column;
    flex-direction: column;
    border-radius: 100px;
    width: 100%;
    font-size: 18px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    -webkit-justify-content: center;
    justify-content: center;
}
.bg-gray {
    background-color: #708997;
}
.step4-form-title {
    font-size: 26px;
    text-transform: uppercase;
    background-color: #97abf6;
    height: 66px;
    line-height: 66px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    color: white;
    font-weight: 600;
    padding-left: 12px;
}

.step4-confirm-show {
    border-radius: 4px;
}

.step4-form-content {
    padding: 25px 15px;
}

.max-1088 {
    max-width: 1088px;
    margin: 0 auto;
    font-family: 'Montserrat';
}

.step4-confirm-show {
    border-radius: 8px;
    border: 2px solid #d0d5e3;
    box-shadow: 6px 8px 0 #eff3f5;
}

.font-26 {
    font-size: 26px;
}
.font-20 {
    font-size: 20px;
}

.fontW-bold {
    font-weight: 700;
}
.fontW-semibold {
    font-weight: 600;
}

.btn-bottom.btn-step4 {
    margin-top: 130px;
    margin-bottom: 90px;
}

@media screen and (max-width: 765px) {
    .step4-confirm-show {
        margin: 0 16px;
    }

    .step4-confirm-show .font-26 {
        font-size: 14px;
    }

    .btn-bottom.btn-step4 {
        margin: 40px 0;
    }
}
