.as-benefit-title, .as-benefit-content{
    font-size: 1.6em;
}
.as-wrapper{
    margin-top: -15px;
    text-align: center;
}

.as-banner {
  background-color: #bdd7ff;
  padding: 80px 0;
}

.as-banner img.as-img-banner {
  max-width: 210px;
  vertical-align: baseline;
}

.as-banner h1 {
  text-transform: uppercase;
  color: #133476;
  font-weight: bold;
  font-size: 26px;
}

.as-banner .btn-joining-system {
  display: inline-flex;
  flex-direction: column;
  color: white;
  padding: 10px 25px;
  border-radius: 100px;
  cursor: pointer;
}

.as-banner .btn-joining-system .first-text {
  font-size: 28px;
  line-height: 1;
}

.as-banner .btn-joining-system .second-text {
  font-size: 24px;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 4px;
}

.as-benefit {
    padding-top: 40px;
    padding-bottom: 40px;
}

.as-benefit h2 {
    color: #0c7fe2;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 40px;
    font-size: 24px;
}

.as-benefit .as-benefit-content {
    font-style: italic;
    text-indent: 30px;
    margin-top: 10px;
}

.as-benefit .row img {
    width: 120px;
}

.as-benefit .text-part {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (min-width: 600px) {
    .as-banner h1 {
        font-size: 28px !important;
    }
    .as-benefit h2 {
        font-size: 26px !important;
    }
}

@media screen and (min-width: 960px) {
    .as-banner h1 {
        font-size: 2.5rem !important;
    }
    .as-benefit h2 {
        font-size: 2rem !important;
    }
}
