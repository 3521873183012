.part ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.part ul li:before {
  content: '+';
  margin-right: 4px;
}

.detail-sup-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}
.detail-sup-wrapper .detail-sup-desc {
  border-top: 1px solid #bbbbbb;
  padding: 15px 30px;
  word-break: break-word;
}
.detail-sup-1 {
  padding: 30px;
}

.detail-sup-1 img.detail-img {
  min-width: 150px;
  min-height: 150px;
  max-width: 100%;
  /*border-radius: 50%;*/
  background-color: grey;
}

.detail-sub-avt {
  text-align: left;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .detail-sub-avt {
    text-align: center !important;
  }
  .company-name {
      margin-top: 15px;
  }
}
