.each-fade {
    display: flex;
    width: 100%;
}

.each-fade > div {
    width: 100%;
}

.each-fade > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.each-fade p {
    width: 25%;
    font-size: 1em;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
}

img.cpn-pro-item--img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    vertical-align: inherit;
    position: relative;
    top: 0;
    transform: translateY(-50%);
}
.homepage-banner{
    margin-top: -15px;
}
.homepage-wrapper .banner{
    margin-top: -15px;
    margin-bottom: -15px;
}

.search-panel .search-content {
    transform: translateY(0px);
    border-radius: 4px;
}

.search-content .pd-30 {
    padding: 30px;
}

.search-panel .advance-search {
    border: none;
    background: none;
    margin-top: 15px;
}

.search-panel .btn-wrapper {
    min-width: 450px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.search-panel .selection button {
    border-radius: 4px;
    border: 1px solid #0c7fe2;
    /* min-width: 200px; */
}

.search-panel .selection {
    max-width: 450px;
}

.search-panel .right-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.join-onebook {
    display: flex;
    flex-direction: column;
    border-radius: 100px;
    width: 100%;
    font-size: 24px;
    max-width: 250px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    justify-content: center;
}

.choose-service {
    isplay: flex;
    flex-direction: column;
    border-radius: 100px;
    width: 100%;
    font-size: 24px;
    max-width: 280px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    padding: 10px
}
.search-panel.container.back {
    padding-left: 0px;
    padding-right: 0px;
}
@media screen and (min-width: 1024px) {
    .search-panel .search-content {
        transform: translateY(0px);
    }
    .search-panel.container.back {
        margin-top: -30px;
    }
}

img.emp-avt {
    max-width: 100%;
    width: 300px;
    height: 250px;
}

p.emp-name {
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
}

p.emp-des {
    font-size: 16px;
    color: white;
    margin-bottom: 5px;
}

.company-professional .card {
    box-shadow: 2px 2px 4px #86a8fd;
    max-width: 300px;
    margin: 0 auto;
}

.company-professional .card-body {
    box-shadow: 0 0 5px 0 #86a8fd;
}

.emp-location {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #c7c7ff, #ffffff00);
    color: white;
    width: 100%;
    text-align: left;
    padding: 2px 10px;
}

.btn-see-all {
    text-transform: capitalize;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 0 4px #86a8fd;
    width: 350px;
    max-width: 100%;
}

.infrastructure .map {
    background: url('../../../assets/images/a.png') center center no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infrastructure .map img {
    width: 200px;
    height: 200px;
}

.img-medium {
    width: 40px;
    height: 40px;
}

.readmore-ob {
    margin: 20px auto;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 4px;
    box-shadow: 0 0 4px #86a8fd;
    width: 350px;
    max-width: 100%;
}

.readmore-ob span {
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
}

.customer-avt {
    max-width: 100%;
    width: 120px;
    height: 120px;
}

.customer-desc {
    text-align: left;
    word-break: break-word;
    margin-bottom: 20px;
    font-style: italic;
}

.customer-company {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
}

.customer-rate {
    background-color: #eff8fe;
    padding-top: 30px;
    padding-bottom: 30px;
}
.article-content {
    font-weight: bold;
}
.article-avt {
    background-color: grey;
    width: 100%;
    max-width: 100%;
    height: 250px;
}

.cpn-des {
    text-transform: uppercase;
    margin-top: 15px;
    color: #2f3b59;
    font-size: 15px;
    max-width: 100%;
    word-break: break-word;
    padding: 0 15px;
    text-align: center;
}
img.cpn-des-img {
    /*width: 60px;*/
    height: 50px;
    margin: 0 auto;
}
img.cpn-sv-img {
    /*width: 150px;*/
    /*height: 170px;*/
    height: 80px;
    max-width: 100%;
    margin: 0 auto;
}

.cpn-sv-des {
    font-size: 18px;
}

.customer-rate-item {
    box-shadow: 0 0 4px 1px #bfbebe;
    max-width: 280px;
    margin: 0 auto;
    padding: 25px 15px;
    background-color: #fff;
}
h2.mb-5.app-title.sub-title {
    padding-top: 4rem;
}
.cpn-pro-item--rate span {
    margin-right: 0px;
}
.infrastructure.container {
    background-color: #ffff;
    padding-top: 4rem;
}
.logo-cty{
    height: 100px;
}
p.danh-gia {
    color: #595959;
}
@media screen and (max-width: 600px) {
    .cpn-des {
        font-size: 16px;
    }
    img.cpn-sv-img {
        width: 60px;
        height: 80px;
    }
}

.cpn-pro-item {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    /*background-color: white;*/
    height: 380px;
    box-shadow: 0 0 4px 1px #bfbebe;
    border-radius: 4px;
    background: url('../../../assets/images/home_blue_bg.png') center center no-repeat;
    overflow: hidden;
    text-align: center;
    align-items: center;
}

.cpn-pro-item--img {
    position: absolute;
    /*top: 0;*/
    /*left: 0;*/
    bottom: 0;
    width: 100%;
    z-index: 10;
    /*height: 100%;*/
    overflow: hidden;
    border-radius: 4px;
}

.cpn-pro-item--rate {
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    text-align: center;
    line-height: 30px;
}

.cpn-pro-item--des {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    z-index: 14;
    color: white;
    text-align: center;
}
.company-description.container {
    background-color: #ffff;
}
.company-professional.container {
    background-color: #ffff;
}
h4.title.color-main-dark.mb-3 {
    font-weight: bold;
}
