
.root {
    float: right;
    margin: 6px 0 0;
}

.link {
    display: inline-block;
    padding: 3px 8px;
    text-decoration: none;
    font-size: 1.125em; /* ~18px */
}

.link,
.link:active,
.link:visited {
    color: rgba(255, 255, 255, 0.6);
}

.link:hover {
    color: rgba(255, 255, 255, 1);
}

.highlight {
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.15);
    color: #fff;
}

.highlight:hover {
    background: rgba(0, 0, 0, 0.3);
}

.spacer {
    color: rgba(255, 255, 255, 0.3);
}

.inputPassword {
    /*height: 35px;*/
    /*!*background-color: blue;*!*/
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
}

.background-btn {
    background-color: #2DA5FF;
}

.border-input-error {
    border-color: red !important;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
}

.font-err {
    color: red;
    font-size: xx-small;
    width: auto !important;
}

.width-btn {
    width: 100px !important;
}

.modal-body {

}

.modal-assign-order .modal-content .modal-body {
    overflow: visible !important;
}

.modal-content .modal-body {
    overflow: visible !important;
}

.modal-content .modal-body.modal-create-new-sup {
    overflow: auto !important;
}


.modal-body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.modal-body::-webkit-scrollbar {
    width: 4px;
    background-color: #ccc;
    height: 80% !important;
    max-height: 650px !important;
    vertical-align: middle !important;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: #8ad2e3 !important;
    border-radius: 16px;
}

/*=====scrollbar-modal=====*/

/*.modal-body::-webkit-scrollbar {*/
/*    width: 0 !important;*/
/*    display: none !important;*/
/*}*/

/*.modal-body {*/
/*    -ms-overflow-style: none !important;*/
/*}*/

/*.modal-body {*/
/*    overflow: -moz-scrollbars-none !important;*/
/*}*/

/*.modal-body {*/
/*    -moz-appearance: menuimage !important;*/
/*}*/

.modal-size-large {
    max-width: 900px !important;
}

.modal-size-medium {
    max-width: 700px !important;
}

.modal-size-small {
    max-width: 600px !important;
}

.modal-content .modal-body {
    overflow: auto !important;
    overflow-x: hidden !important;
}


.modal-open .modal::-webkit-scrollbar {
    width: 0 !important;
    display: none !important;
}
.modal-open .modal {
    -ms-overflow-style: none !important;
}

.modal-open .modal {
    overflow: -moz-scrollbars-none !important;
}

.modal-open .modal {
    -moz-appearance: menuimage !important;
}

.add-new-payment {
    overflow: visible !important;
}
