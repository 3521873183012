.app-logo {
  width: 200px;
}

.sign-up-btn {
  border-radius: 100px !important;
  /*padding: 0 15px;*/
  margin-left: 25px;
  border: 1px solid #85c525;
}

.sign-in-btn {
  border-radius: 100px !important;
  /*padding: 0 15px;*/
  margin-left: 25px;
  border: 1px solid #8596a2 !important;
}

.btn-sign-in-tablet {
    border: none;
    background: none;
}

@media screen and (min-width: 1920px) {
  .ob-navbar {
    padding: 25px 1rem;
  }
}
