
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-pdf:before { content: "\f100"; }
.flaticon-question:before { content: "\f101"; }
.flaticon-delete:before { content: "\f102"; }
.flaticon-server-more:before { content: "\f103"; }
.flaticon-phone:before { content: "\f104"; }
.flaticon-edit:before { content: "\f105"; }
.flaticon-signup:before { content: "\f106"; }
.flaticon-file:before { content: "\f107"; }
.flaticon-next:before { content: "\f108"; }
.flaticon-back:before { content: "\f109"; }
.flaticon-quote-right:before { content: "\f10a"; }
.flaticon-quote-left:before { content: "\f10b"; }
.flaticon-user-plus:before { content: "\f10c"; }
.flaticon-notepad:before { content: "\f10d"; }
.flaticon-play:before { content: "\f10e"; }
.flaticon-checked:before { content: "\f10f"; }
.flaticon-camera:before { content: "\f110"; }
.flaticon-search:before { content: "\f111"; }
.flaticon-close:before { content: "\f112"; }
.flaticon-profile:before { content: "\f113"; }
.flaticon-meeting-manage:before { content: "\f114"; }
.flaticon-group:before { content: "\f115"; }
.flaticon-meeting-calendar:before { content: "\f116"; }
.flaticon-manage-contract:before { content: "\f117"; }
.flaticon-meeting-setting:before { content: "\f118"; }
.flaticon-circle-plus:before { content: "\f119"; }
.flaticon-conference:before { content: "\f11a"; }
.flaticon-right:before { content: "\f11b"; }
