.home-documents {
    padding-top: 20px;
}
.documents .nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.documents ul, ol {
    margin-top: 0;
    margin-bottom: 10px;
}
.documents ul {
    /*display: block;*/
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.documents ul.nav-list li {
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
}
.documents li {
    line-height: 24px;
}
.documents ul.nav-list li.active > a {
    font-weight: bold;
}
.documents ul.nav-list li a:hover {
    background-color: #eee;
    text-decoration: none;
}
.documents .nav > li > a:hover, .nav > li > a:focus {
    background: transparent;
}
.documents .nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #eee;
}
.documents :focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
}
.documents aside ul.nav-list > li > a {
    color: #666;
    font-size: 0.9em;
}
.documents .clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after {
    display: table;
    content: " ";
}
.documents ul.nav-list li a:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #333;
    margin-left: 10px;
    margin-right: 5px;
    position: relative;
}
.documents ul.nav-list li a {
    -webkit-transition: background 0.1s;
    -moz-transition: background 0.1s;
    transition: background 0.1s;
    border-bottom: 1px solid #EDEDDE;
    padding: 8px 0;
    display: block;
    color: #666;
    font-size: 0.9em;
    text-decoration: none;
}
.documents .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.documents ul.nav-list li ul {
    list-style: none;
    margin: 0 0 0 25px;
    padding: 0;
}
.documents ul ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
}
.documents .alert.alert-sm {
    padding: 5px 10px;
    font-size: 1.1em;
}
.documents .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.documents .alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.documents h2 {
    font-size: 1.7em;
    color: #0088cc;
    line-height: 150%;
}
.documents .list.list-icons {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
}
.documents .list.list-borders li:last-child {
    border-bottom: 0;
}
.documents .list.list-icons.list-icons-lg li {
    padding-top: 5px;
    padding-left: 32px;
}
.documents .list.list-borders li {
    border-bottom: 1px solid #DBDBDB;
    margin-top: 9px;
    margin-bottom: 0;
    padding-bottom: 9px;
}
.documents .list.list-icons li {
    position: relative;
    padding-left: 25px;
}
.documents .list.list-icons.list-icons-lg li .fa, .list.list-icons.list-icons-lg li .icons {
    font-size: 1.5em;
}
.documents html .list-primary.list-icons li .fa, html .list-primary.list-icons li .icons {
    color: #0088cc;
    border-color: #0088cc;
}
.documents .list.list-icons li .fa, .list.list-icons li .icons {
    color: #0088cc;
    border-color: #0088cc;
}
.documents .list.list-icons li .fa, .list.list-icons li .icons {
    position: absolute;
    left: 0;
    top: 5px;
}
#file-name{
    display: none;
}
