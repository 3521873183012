
.step1-item {
    border-radius: 10px;
    border: 1px solid #D0D5E3;
    box-shadow: 3px 5px 5px 1px #EFF3F5;
    transition: box-shadow 0.5s, border 0.2s;
    cursor: pointer;
    max-width: 425px;
    width: 33.33%;
    margin: 0 10px;
}

.step1-item .step1-item-title {
    text-transform: uppercase;
    padding: 20px 15px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 88px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    background-color: #9fd3ff;
    color: #062942;
    line-height: 36px;
}

.step1-item:first-of-type .step1-item-title {
    background-color: #8cbfff;
}

.step1-item:last-of-type .step1-item-title {
    background-color: #b7e8ff;
}

.step1-item .step1-item-content {
    padding: 20px 15px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 400px;
}

.list-dot-blue li,
.list-dot-blue {
    font-size: 22px;
    font-family: 'Montserrat';
    color: #062942;
}

.list-dot-blue li {
    padding: 5px 0;
}

.list-dot-blue li:before {
    content: '';
    display: inline-block;
    height: 11px;
    width: 11px;
    background-color: #67bcfd;
    border-radius: 50%;
    margin-right: 15px;
}

.custom-radios input[type="radio"] {
    display: none;
}
.custom-radios input[type="radio"] + label {
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 14px;
}
.custom-radios input[type="radio"] + label span {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: -1px 4px 0 0;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    line-height: 36px;
}

.custom-radios input[type="radio"] + label span img {
    opacity: 0;
    transition: all .3s ease;
}
.custom-radios input[type="radio"]#color-1 + label span {
    background-color: #2ecc71;
}
.custom-radios input[type="radio"]#color-2 + label span {
    background-color: #3498db;
}

.custom-radios input[type="radio"]:checked + label span img {
    opacity: 1;
}

.stepper-router {
    font-size: 16px;
}

.step-title-large {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-top: 32px !important;
    margin-bottom: 32px !important;
    font-family: 'Montserrat';
    color: #0d83dd;
}

.max-1480 {
    max-width: 1480px;
    margin: 0 auto;
    font-family: 'Montserrat';
}

.step-top .step {
    font-size: 28px;
    font-weight: 700;
    font-family: 'Montserrat';
    min-width: 200px;
    padding: 0 15px;
    text-align: center;
}

.step-top .step .step-number .fa-check{
    font-size: 32px;
}

.step-top .step .step-number {
    height: 48px;
    width: 48px;
    background-color: #67bcfd;
    border-radius: 50%;
    text-align: center;
    line-height: 1;
    color: #1133a9;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
}

.step-top {
    padding-top: 20px;
}

.step-top .step .sub-number {
    position: absolute;
    bottom: -18px;
    left: -8px;
    color: #b9e1ff;
}

.step-top .step .text {
    font-size: 20px;
    position: relative;
    top: 10px;
    color: #062942;
    font-weight: 300;
}

.step-top .step.active .text {
    font-weight: 700;
}

.step-top .step-2 .step-number {
    color: #16330a;
    background-color: #7bde78;
}

.step-top .step-2 .sub-number {
    color: #cdf7cb;
}

.step-top .step-3 .step-number {
    color: #754113;
    background-color: #f5da66;
}

.step-top .step-3 .sub-number {
    color: #f7eeaf;
}

.step-top .step-4 .step-number {
    color: #16330a;
    background-color: #97abf6;
}

.step-top .step-4 .sub-number {
    color: #d0daff;
}

.btn-bottom {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 66px;
}

.btn-bottom button {
    min-width: 203px;
    font-size: 22px;
    font-family: 'Montserrat';
}

.btn-bottom .btn-cancel-stepper {
    margin-right: 60px;
}

.btn-bottom .btn-cancel-stepper:hover {
    background-color: #0075dd;
    color: #fff;
}

.btn-bottom .btn-next-stepper {
    background-image: linear-gradient(to right, #3ba5f7 , #0075dd);
}

.btn-bottom .btn-next-stepper:hover {
    color: #fff;
    background-image: linear-gradient(to right, #0075dd , #3ba5f7);
}

@media screen and (max-width: 1600px) {
    .step1-item .step1-item-title {
        font-size: 18px;
        line-height: 1.5;
    }

    .list-dot-blue li, .list-dot-blue {
        font-size: 16px;
    }
}

@media screen and (max-width: 765px) {
    .step1-item  {
        max-width: 100%;
        width: 100%;
        margin-top: 20px;
        margin: 15px;
    }

    .step4-form-title {
        font-size: 20px !important;
    }

    .step-top {
        flex-wrap: wrap;
        padding-top: 0;
    }

    .step-top .step {
        min-width: auto;
        width: 50%;
        margin-bottom: 10px;
    }

    .stepper-router {
        padding-left: 15px;
    }
    
    .step-top .step .text {
        white-space: nowrap;
    }

    .step1-item .step1-item-content {
        min-height: 200px;
    }

    .max-1480 {
        flex-wrap: wrap;
    }

    .btn-bottom {
        margin: 30px 15px;
    }

    .btn-bottom button {
        min-width: 150px;
    }

    .btn-bottom .btn-cancel-stepper {
        margin-right: 20px;
    }
}
