.inputValue {
    height: 35px;
    /*background-color: blue;*/
    border-radius: 5px;
    border-width: 1px;
    width: 65%
}

.buttonModal {
    min-width: 90px;
}

.lableModal {
    font-size: 16px;
}

.chooseLabel {
    height: 38px;
}







