.step2-item-content-title {
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
}
.step2-btn-buy {
    height: 50px;
    text-align: center;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    line-height: 50px;
    font-weight: bold;
    font-size: 18px;
}

.step2-item-content {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 20px 15px;
}

.step2-item {
    border-radius: 4px;
    border: 1px solid #D0D5E3;
    box-shadow: 3px 5px 5px 1px #EFF3F5;
}
.step2-form-content form{
    min-height: 164px;
}

.step2-form-content1 form{
    min-height: 80px;
}

.btn-bottom.btn-step2 {
    margin-top: 36px;
    margin-bottom: 66px;
}

@media screen and (max-width: 765px) {
    .btn-bottom.btn-step2 {
        margin: 40px 0;
    }
}
